<template>
  <v-menu
    v-model="menu"
    offset-y
    left
    nudge-bottom="22"
    :close-on-content-click="false"
    :elevation="$vuetify.theme.dark ? 9 : 8"
    content-class="list-style notification-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :content="newCount"
        :value="newCount"
        color="error"
        bottom
        overlap
      >
        <v-icon
          v-bind="attrs"
          v-on="on"
        >
          {{ icons.mdiBellOutline }}
        </v-icon>
      </v-badge>
    </template>
    <v-card class="app-bar-notification-content-container">
      <perfect-scrollbar
        class="ps-user-notifications"
        :options="perfectScrollbarOptions"
      >
        <v-list class="py-0">
          <!-- Header -->
          <v-list-item
            class="d-flex"
          >
            <div class="d-flex align-center justify-space-between flex-grow-1">
              <span class="font-weight-semibold">{{ $t('Notifications') }}</span>
              <v-chip
                class="v-chip-light-bg primary--text font-weight-semibold"
                small
              >
                {{ newCount + ' ' + $t('NewNotifications') }}
              </v-chip>
            </div>
          </v-list-item>
          <v-divider></v-divider>
          <!-- Notifications -->
          <template v-for="(notification, index) in notifications">
            <v-list-item
              :key="notification.id"
              @click="goToReportAndReadNotification(notification.reportId, notification.id)"
            >
              <!-- Avatar -->
              <v-badge
                :content="!notification.isRead"
                :value="!notification.isRead"
                color="error"
                left
                dot
                overlap
              >
                <v-list-item-avatar
                  class="v-avatar-light-bg success--text justify-center"
                  size="38"
                >
                  <span
                    class="text-lg"
                  >XLS</span>
                </v-list-item-avatar>
              </v-badge>
              <!-- Content -->
              <v-list-item-content class="d-block">
                <v-list-item-title class="text-sm font-weight-semibold">
                  {{ notification[`message${$ucFirst($i18n.locale)}`] }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-sm">
                  {{ notification.createdDate ? notification.createdDate.substr(0, 10) : '' }} {{ notification.createdDate ? notification.createdDate.substr(11, 8) : '' }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn
                  icon
                  @click.stop="removeNotification(notification.id)"
                >
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                </v-btn>
              </v-list-item-icon>
              <!-- Item Action/Time -->
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
          <v-list-item v-if="notifications.length === 0">
            <v-list-item-content class="d-block">
              <v-list-item-title class="text-sm font-weight-semibold">
                {{ $t('NoNotificationsYet') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            key="read-all-btn"
            class=""
          >
            <v-btn
              block
              color="primary"
              @click="readAllNotifications()"
            >
              {{ $t('ReadAllNotifications') }}
            </v-btn>
          </v-list-item>
          <v-list-item
            key="remove-all-btn"
            class=""
          >
            <v-btn
              block
              color="primary"
              @click="removeAllNotifications()"
            >
              {{ $t('RemoveAllNotifications') }}
            </v-btn>
          </v-list-item>
        </v-list>
      </perfect-scrollbar>
    </v-card>
  </v-menu>
</template>

<script>
import { mdiBellOutline, mdiClose } from '@mdi/js'

// 3rd Party
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    // 3rd Party
    PerfectScrollbar,
  },
  data() {
    return {
      newCount: 0,
      selected: [],
      menu: false,
      perfectScrollbarOptions: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      notifications: [],
      user: JSON.parse(localStorage.getItem('userData')),
      icons: {
        mdiBellOutline,
        mdiClose,
      },
    }
  },
  mounted() {
    this.getNotifications()
    this.interval = setInterval(() => {
      this.getNotifications()
    }, 15000)
  },
  methods: {
    getNotifications() {
      const audio = new Audio('/files/notification_bell.mp3')
      this.$http
        .get(`${this.$apiBaseURL}/notifications`, {
          params: { userId: this.$getUserId(), appName: 'md-dashboard-app' },
        })
        .then(response => {
          this.notifications = response.data !== null ? response.data : []

          const unreadMessages = this.notifications.filter(c => c.isReceived === false)
          if (unreadMessages.length > 0) {
            audio.play()
            const notifId = []
            unreadMessages.forEach(item => {
              notifId.push(item.id)
            })
            this.$http
              .put(`${this.$apiBaseURL}/notifications/action`, {
                notificationIds: notifId,
                actionType: 'receive',
                appName: 'md-dashboard-app',
              })
              .then(() => {})
          }

          const newNotifcations = this.notifications.filter(c => c.isRead === false)
          this.newCount = newNotifcations.length
        })
    },
    removeNotification(id) {
      this.$http
        .put(`${this.$apiBaseURL}/notifications/action`, {
          notificationIds: [id],
          actionType: 'close',
          appName: 'md-dashboard-app',
        })
        .then(() => {
          this.getNotifications()
        })
    },
    readAllNotifications() {
      const notifId = []
      this.notifications.forEach(item => {
        notifId.push(item.id)
      })
      this.$http
        .put(`${this.$apiBaseURL}/notifications/action`, {
          notificationIds: notifId,
          actionType: 'read',
          appName: 'md-dashboard-app',
        })
        .then(() => {
          this.getNotifications()
        })
    },
    removeAllNotifications() {
      const notifId = []
      this.notifications.forEach(item => {
        notifId.push(item.id)
      })
      this.$http
        .put(`${this.$apiBaseURL}/notifications/action`, {
          notificationIds: notifId,
          actionType: 'close',
          appName: 'md-dashboard-app',
        })
        .then(() => {
          this.getNotifications()
        })
    },
    goToReportAndReadNotification(reportId, id) {
      this.$http
        .put(`${this.$apiBaseURL}/notifications/action`, {
          notificationIds: [id],
          actionType: 'read',
          appName: 'md-dashboard-app',
        })
        .then(() => {
          this.getNotifications()
          this.menu = false
          this.$router.push({ name: 'advertisers-generate-report', params: { report_id: reportId } })
        })
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>
