export default () => {
  const resolveRouteGroup = (item, route) => {
    if (item.groups === undefined) return true

    const routeGroup = route.path.split('/')[1]
    const found = item.groups.find(element => element === routeGroup)

    return found !== undefined
  }

  return {
    resolveRouteGroup,
  }
}
