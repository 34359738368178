import mock from '@/@fake-db/mock'

const dataProviders = [
  {
    ctr: 0.98,
    uniqClicks: 575,
    bounce: 0,
    costStat: 19202.16,
    uniqViews: 38178,
    hits: 1,
    duration: 30,
    depth: 1,
    rf: 1.57,
    providerId: 46,
    clicks: 589,
    providerName: 'AdSpend',
    campaignId: 11728,
    views: 59911,
    costPer: 325.46,
  },
  {
    ctr: 0.99,
    uniqClicks: 669,
    bounce: 0,
    costStat: 21942.28,
    uniqViews: 43947,
    hits: 1,
    duration: 300,
    depth: 1,
    rf: 1.57,
    providerId: 51,
    clicks: 683,
    providerName: 'Getintent',
    campaignId: 11728,
    views: 69000,
    costPer: 318,
  },
]
const providers = [
  {
    key: 51,
    text: 'Getintent',
  },
  {
    key: 46,
    text: 'AdSpend',
  },
]

mock.onGet('/providers/data').reply(() => [200, dataProviders])
mock.onGet('/providers/').reply(() => [200, providers])
