import mock from '@/@fake-db/mock'

const dataCreative = [
  {
    creative_id: 50721,
    creative_name: 'olv_10sec',
    total_current_period:
    {
      ctr: 1.82,
      uniq_count: 1003222,
      ref_count: 22949,
      unique_clicks: 22070,
      show_count: 1219219,
    },
    total_all_period: {
      ctr: 1.93,
      uniq_count: 1033222,
      ref_count: 622949,
      unique_clicks: 622070,
      show_count: 5319219,
    },
    statistics: [
      {
        date_string: '01-11-2022',
        ctr: 2.31,
        uniq_count: 99661,
        ref_count: 2886,
        unique_clicks: 2784,
        rf: 1.23,
        show_count: 124441,
      },
      {
        date_string: '02-11-2022',
        ctr: 2.31,
        uniq_count: 99661,
        ref_count: 2886,
        unique_clicks: 2784,
        rf: 1.23,
        show_count: 124441,
      },
      {
        date_string: '03-11-2022',
        ctr: 2.31,
        uniq_count: 99661,
        ref_count: 2886,
        unique_clicks: 2784,
        rf: 1.23,
        show_count: 124441,
      },
      {
        date_string: '04-11-2022',
        ctr: 2.31,
        uniq_count: 99661,
        ref_count: 2886,
        unique_clicks: 2784,
        rf: 1.23,
        show_count: 124441,
      },
    ],
  },
  {
    creative_id: 50720,
    creative_name: 'olv_20sec',
    total_current_period:
    {
      ctr: 1.82,
      uniq_count: 1003222,
      ref_count: 22949,
      unique_clicks: 22070,
      show_count: 1219219,
    },
    total_all_period: {
      ctr: 1.93,
      uniq_count: 1033222,
      ref_count: 622949,
      unique_clicks: 622070,
      show_count: 5319219,
    },
    statistics: [
      {
        date_string: '01-11-2022',
        ctr: 2.31,
        uniq_count: 99661,
        ref_count: 2886,
        unique_clicks: 2784,
        rf: 1.23,
        show_count: 124441,
      },
      {
        date_string: '02-11-2022',
        ctr: 2.31,
        uniq_count: 99661,
        ref_count: 2886,
        unique_clicks: 2784,
        rf: 1.23,
        show_count: 124441,
      },
      {
        date_string: '03-11-2022',
        ctr: 2.31,
        uniq_count: 99661,
        ref_count: 2886,
        unique_clicks: 2784,
        rf: 1.23,
        show_count: 124441,
      },
      {
        date_string: '04-11-2022',
        ctr: 2.31,
        uniq_count: 99661,
        ref_count: 2886,
        unique_clicks: 2784,
        rf: 1.23,
        show_count: 124441,
      },
    ],
  },
  {
    creative_id: 50722,
    creative_name: 'olv_30sec',
    total_current_period:
    {
      ctr: 1.82,
      uniq_count: 1003222,
      ref_count: 22949,
      unique_clicks: 22070,
      show_count: 1219219,
    },
    total_all_period: {
      ctr: 1.93,
      uniq_count: 1033222,
      ref_count: 622949,
      unique_clicks: 622070,
      show_count: 5319219,
    },
    statistics: [
      {
        date_string: '01-11-2022',
        ctr: 2.31,
        uniq_count: 99661,
        ref_count: 2886,
        unique_clicks: 2784,
        rf: 1.23,
        show_count: 124441,
      },
      {
        date_string: '02-11-2022',
        ctr: 2.31,
        uniq_count: 99661,
        ref_count: 2886,
        unique_clicks: 2784,
        rf: 1.23,
        show_count: 124441,
      },
      {
        date_string: '03-11-2022',
        ctr: 2.31,
        uniq_count: 99661,
        ref_count: 2886,
        unique_clicks: 2784,
        rf: 1.23,
        show_count: 124441,
      },
      {
        date_string: '04-11-2022',
        ctr: 2.31,
        uniq_count: 99661,
        ref_count: 2886,
        unique_clicks: 2784,
        rf: 1.23,
        show_count: 124441,
      },
    ],
  },
  {
    creative_id: 50723,
    creative_name: 'olv_5sec',
    total_current_period:
    {
      ctr: 1.82,
      uniq_count: 1003222,
      ref_count: 22949,
      unique_clicks: 22070,
      show_count: 1219219,
    },
    total_all_period: {
      ctr: 1.93,
      uniq_count: 1033222,
      ref_count: 622949,
      unique_clicks: 622070,
      show_count: 5319219,
    },
    statistics: [
      {
        date_string: '01-11-2022',
        ctr: 2.31,
        uniq_count: 99661,
        ref_count: 2886,
        unique_clicks: 2784,
        rf: 1.23,
        show_count: 124441,
      },
      {
        date_string: '02-11-2022',
        ctr: 2.31,
        uniq_count: 99661,
        ref_count: 2886,
        unique_clicks: 2784,
        rf: 1.23,
        show_count: 124441,
      },
      {
        date_string: '03-11-2022',
        ctr: 2.31,
        uniq_count: 99661,
        ref_count: 2886,
        unique_clicks: 2784,
        rf: 1.23,
        show_count: 124441,
      },
      {
        date_string: '04-11-2022',
        ctr: 2.31,
        uniq_count: 99661,
        ref_count: 2886,
        unique_clicks: 2784,
        rf: 1.23,
        show_count: 124441,
      },
    ],
  },
]

const dataCreativeChart = {
  series: [
    {
      name: 'Shows',
      data: [1234, 1334, 1343, 4345],
    },
    {
      name: 'References',
      data: [60, 80, 70, 110],
    },
  ],
  categories: [
    '01-11',
    '02-11',
    '03-11',
    '04-11',
  ],
}

mock.onGet('/creatives/daily/table-data').reply(request => {
  // eslint-disable-next-line camelcase
  const { creative_id } = request.params
  // eslint-disable-next-line camelcase
  const data = dataCreative.find(c => c.creative_id === creative_id)

  return [200, data]
})

mock.onGet('/creatives/daily/chart-data').reply(() => [200, dataCreativeChart])
