/* eslint-disable import/extensions */
import './jwt/index'
import './mobidriven/advertiser-report-generation'
import './mobidriven/advertisers'
import './mobidriven/audience'
import './mobidriven/campaign-statistics'
import './mobidriven/campaigns-list'
import './mobidriven/creatives-daily'
import './mobidriven/creatives-list'
import './mobidriven/creatives-vtr-report'
import './mobidriven/geo'
import './mobidriven/notifications'
import './mobidriven/providers'
import './mobidriven/rate-report'
import './mobidriven/tracking-code'
import './mobidriven/user-create-and-edit'
import './mobidriven/users-list'
import './mobidriven/vtr-report'
import mock from './mock'

/* eslint-enable import/extensions */

mock.onAny().passThrough() // forwards the matched request over network
