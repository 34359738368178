/* eslint-disable indent */
import {
  mdiAccountCircle, mdiAccountDetails, mdiAccountMultiple, mdiAtomVariant, mdiCardAccountDetails, mdiChartBar,
  mdiChartLine, mdiEarth, mdiFileChartOutline, mdiFinance, mdiFormatListText, mdiLink, mdiStar, mdiVideo,
  // eslint-disable-next-line comma-dangle
  mdiXml
} from '@mdi/js'

export default () => {
  const getMainItems = () => [
    {
      title: 'Advertisers',
      icon: mdiAccountMultiple,
      to: 'advertisers',
      resource: 'Public',
      action: 'manage',
      availableFor: ['ROOT', 'SUPER_ADMINS', 'MANAGER_ADMINS', 'MANAGERS'],
    },
    {
      title: 'CampaignsList',
      icon: mdiFormatListText,
      to: { name: 'campaigns-list' },
      resource: 'Public',
      action: 'manage',
    },
    {
      title: 'PPControl',
      icon: mdiXml,
      to: 'pp-control',
      resource: 'TRACKING_PIXEL',
      action: 'manage',
    },
    {
      title: 'Users',
      icon: mdiAccountCircle,
      children: [
        {
          title: 'Users',
          to: 'users-list',
          icon: mdiAccountDetails,
          resource: 'USERS',
          action: 'manage',
        },
        {
          title: 'LinkUser',
          to: 'users-link',
          icon: mdiLink,
          resource: 'LINK_USERS',
          action: 'manage',
        },
      ],
    },
    {
      title: 'Reports',
      icon: mdiFileChartOutline,
      children: [
      {
        title: 'RateReport',
        icon: mdiFinance,
        to: 'rate-report',
        resource: 'REPORT_RATE',
        action: 'manage',
      },
      {
        title: 'VTRReport',
        icon: mdiVideo,
        to: 'vtr-report',
        resource: 'REPORT_VTR',
        action: 'manage',
      },
      {
        title: 'CampaignStatistics',
        icon: mdiChartBar,
        to: { name: 'campaigns-statistics' },
        resource: 'REPORT_CAMPAIGN_STATISTICS',
        action: 'manage',
      },
      {
        title: 'CampaignGeo',
        icon: mdiEarth,
        to: { name: 'campaigns-geo' },
        resource: 'REPORT_CITY',
        action: 'manage',
      },
      {
        title: 'CampaignAuditory',
        icon: mdiCardAccountDetails,
        to: { name: 'campaigns-auditory' },
        resource: 'REPORT_AUDIENCE_CATEGORIES',
        action: 'manage',
      },
      {
        title: 'CampaingProvidersReport',
        icon: mdiChartLine,
        to: { name: 'campaigns-providers' },
        resource: 'REPORT_PROVIDERS',
        action: 'manage',
      },
      {
        title: 'CampaingCreatives',
        icon: mdiStar,
        to: { name: 'campaigns-creatives' },
        resource: 'REPORT_CREATIVES',
        action: 'manage',
      },
    ],
    },
    {
      title: 'ReportGenerationMenu',
      icon: mdiAtomVariant,
      to: 'advertisers-generate-report',
      resource: 'REPORTS_GENERATION',
      action: 'manage',
      availableFor: ['ROOT', 'SUPER_ADMINS', 'MANAGER_ADMINS', 'MANAGERS'],
    },
  ]

  return {
    getMainItems,
  }
}
