import mock from '@/@fake-db/mock'

const dataUsers = [
  {
    id: 231,
    name: 'Root',
    email: 'root@mobidriven.com',
    status: 'active',
    role: 'Root',
  },
  {
    id: 239,
    name: 'Лисинный',
    email: 'lisi@lisi.ru',
    status: 'active',
    role: 'Рекламодатели',
  },
  {
    id: 235,
    name: 'test1_user_provider',
    email: 'test1_user_provider@c.ccc',
    status: 'active',
    role: 'Поставщики',
  },
]

mock.onGet('/users/list').reply(() => [200, dataUsers])
