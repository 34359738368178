import mock from '@/@fake-db/mock'

const data = [
  {
    'cityEng': 'Abakan',
    'country': 'RU',
    'city': 'Абакан',
    latitude: 53.7156,
    'clicks': 3,
    'views': 0,
    'region': 'Хакасия',
    'cityId': 1512236,
    longitude: 91.4292,
  },
  {
    'cityEng': 'Aksay',
    'country': 'RU',
    'city': 'Аксай',
    latitude: 47.2584,
    'clicks': 1,
    'views': 0,
    'region': 'Ростовская область',
    'cityId': 583673,
    longitude: 39.8667,
  },
  {
    'cityEng': 'Al met yevsk',
    'country': 'RU',
    'city': 'Альметьевск',
    latitude: 54.8800,
    'clicks': 2,
    'views': 0,
    'region': 'Татарстан',
    'cityId': 582432,
    longitude: 52.3300,
  },
  {
    'cityEng': 'Moscow',
    'country': 'RU',
    'city': 'Москва',
    'latitude': 55.7522,
    'clicks': 170000,
    'views': 0,
    'region': 'Москва',
    'cityId': 524901,
    'longitude': 37.6156,
  },
  {
    "cityEng": "Nizhniy Novgorod",
    "country": "RU",
    "city": "Нижний Новгород",
    "latitude": 56.3287,
    "clicks": 370,
    "views": 0,
    "region": "Нижегородская область",
    "cityId": 520555,
    "longitude": 44.0020,
  },
  {
    "cityEng": "Novokuznetsk",
    "country": "RU",
    "city": "Новокузнецк",
    "latitude": 53.7557,
    "clicks": 750,
    "views": 0,
    "region": "Кемеровская область",
    "cityId": 1496990,
    "longitude": 87.1099,
  },
  {
    "cityEng": "Novokuybyshevsk",
    "country": "RU",
    "city": "Новокуйбышевск",
    "latitude": 53.1072,
    "clicks": 1500,
    "views": 0,
    "region": "Самарская область",
    "cityId": 518659,
    "longitude": 49.9253,
  },
]

const countPeriod = [
  {
    count: 15,
  },
]

const countAllPeriod = [
  {
    count: 155,
  },
]

mock.onGet('/geo/data').reply(() => [200, data])
mock.onGet('/geo/count/period').reply(() => [200, countPeriod])
mock.onGet('/geo/count/all').reply(() => [200, countAllPeriod])
