import mock from '@/@fake-db/mock'

const dataNotifications = [
  {
    userId: 43,
    id: 1,
    messageRu: 'Отчет 43_2022-04-27_085915817 сформирован',
    messageEn: 'Report 43_2022-04-27_085915817 generated',
    createdDate: '2022-04-27 09:00:01',
    isRecieved: true,
    isRead: false,
    isClosed: false,
    reportId: 5,
  },
  {
    userId: 43,
    id: 2,
    messageRu: 'Отчет 43_2022-04-26_155942741 сформирован',
    messageEn: 'Report 43_2022-04-26_155942741 generated',
    createdDate: '2022-04-26 16:00:01',
    isRecieved: true,
    isRead: false,
    isClosed: false,
    reportId: 5,
  },
  {
    userId: 43,
    id: 3,
    messageRu: 'Отчет 43_2022-04-26_155614305 сформирован',
    messageEn: 'Report 43_2022-04-26_155614305 generated',
    createdDate: '2022-04-26 15:57:00',
    isRecieved: true,
    isRead: true,
    isClosed: false,
    reportId: 5,
  },
  {
    userId: 43,
    id: 4,
    messageRu: 'Отчет 43_2022-04-26_094644462 сформирован',
    messageEn: 'Report 43_2022-04-26_094644462 generated',
    createdDate: '2022-04-26 09:47:00',
    isRecieved: true,
    isRead: false,
    isClosed: true,
    reportId: 5,
  },
  {
    userId: 153,
    id: 5,
    messageRu: 'Отчет 43_2022-04-26_082538778 сформирован',
    messageEn: 'Report 43_2022-04-26_082538778 generated',
    createdDate: '2022-04-26 08:26:00',
    isRecieved: false,
    isRead: false,
    isClosed: true,
    reportId: 5,
  },
]

mock.onGet('/notifications/get').reply(request => {
  // eslint-disable-next-line camelcase
  const { user_id } = request.params
  // eslint-disable-next-line camelcase
  const data = dataNotifications.filter(c => c.userId === user_id && c.isClosed !== true)

  return [200, data]
})
mock.onPost('/notifications/set-readed').reply(() => [200, 'ok'])
mock.onPost('/notifications/set-recieved').reply(() => [200, 'ok'])
mock.onPost('/notifications/set-closed').reply(() => [200, 'ok'])
mock.onPost('/notifications/set-closed-all').reply(() => [200, 'ok'])
