export const hasAccess = function (to) {
  let allowed = false
  if (to.meta.availableFor === undefined) {
    return true
  }
  const user = JSON.parse(localStorage.getItem('userData'))
  if (user) {
    const found = to.meta.availableFor.find(element => element === user.role)
    allowed = found !== undefined
  }

  return allowed
}

export const _ = undefined
