export default {
  install(Vue) {
    // define an instance method
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$checkRule = function (text) {
      let allowed = false
      if (text === undefined) {
        return undefined
      }
      const rules = JSON.parse(localStorage.getItem('userRules'))
      if (rules) {
        const found = rules.find(element => element === text)
        allowed = found !== undefined
      }

      return allowed
    }

    // define an instance method
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$checkAbility = function (text) {
      let allowed = false
      if (text === undefined) {
        return undefined
      }
      const abilities = JSON.parse(localStorage.getItem('userAbility'))
      if (abilities) {
        const found = abilities.find(element => element.subject === text)
        allowed = found !== undefined
      }

      return allowed
    }

    // define an instance method
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$checkRole = function (text) {
      let allowed = false
      if (text === undefined) {
        return undefined
      }
      const user = JSON.parse(localStorage.getItem('userData'))
      if (user) {
        if (Array.isArray(text)) {
          const found = text.find(element => element === user.role)
          allowed = found !== undefined
        } else {
          allowed = text === user.role
        }
      }

      return allowed
    }
  },
}
