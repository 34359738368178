import Vue from 'vue'

export default () => {
  const resolveNavRule = item => {
    if (item.availableFor === undefined) return true

    return Vue.prototype.$checkRole(item.availableFor)
  }

  return {
    resolveNavRule,
  }
}
