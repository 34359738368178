import * as XLSX from 'xlsx-js-style'
export default {
  install(Vue) {
    // define an instance method
    // https://service.ggads.io/dashboard/api
    // 192.168.62.112:7080 - тестовый
    // https://service.mobidriven.com/dashboard/api

    // eslint-disable-next-line no-param-reassign
    // Vue.prototype.$apiBaseURL = 'https://service.mobidriven.com/dashboard/api'

    // eslint-disable-next-line no-param-reassign, func-names
    Vue.prototype.$exportToExcel = function (headers, reportData, fileName, reportHeader, colWidthArray) {
      //создаем список индексов екселя
      const excelHeadersIndexes = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ]

      //подготавливаем заголовки для екселя, задавая им индекс.
      var headersForexcel = []

      for (let index = 0; index < headers.length; index++) {
        if (headers[index].isForExcel) {
          headersForexcel.push(headers[index])
        }
      }

      for (let index = 0; index < headersForexcel.length; index++) {
        headersForexcel[index].excelHedaerIndex = excelHeadersIndexes[index]
      }

      const wb = XLSX.utils.book_new()
      const rows = []

      const sl = XLSX.utils.json_to_sheet(rows)

      // Рабочая область
      sl['!ref'] = 'A1:Z1000'

      // соединение ячеек
      sl['!merges'] = [{ s: { r: 0, c: headersForexcel.length - 1 }, e: { r: 0, c: 0 } }]

      // Ширина столбцов
      sl['!cols'] = colWidthArray

      // верхняя шапка
      sl.A1 = {
        t: 's',
        v: reportHeader,
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, alignment: { horizontal: 'center' } },
      }

      // шапка таблицы

      let headerRowIndex = 2

      headersForexcel.forEach(item => {
        sl[`${item.excelHedaerIndex}${headerRowIndex}`] = {
          t: 's',
          v: item.text,
          s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
        }
      })

      // Заполняем таблицу через AoA

      const getVal = (ob, s) => s.split('.').reduce((o, k) => o?.[k], ob)
      const getType = ob => {
        if (typeof ob === 'number') {
          return 'n'
        }
        if (typeof ob === 'string') {
          return 's'
        }
      }

      let count = 3
      reportData.forEach(element => {
        headersForexcel.forEach(item => {
          let value = getVal(element, item.value)
          if (item.isDate) value = value.substring(0, 10)
          sl[`${item.excelHedaerIndex}${count}`] = {
            t: getType(value),
            v: value,
          }
        })

        // eslint-disable-next-line no-plusplus
        count++
      })

      // Добавляем к документу
      XLSX.utils.book_append_sheet(wb, sl, 'Sheet1')

      XLSX.writeFile(wb, fileName)
    }
  },
}
