import mock from '@/@fake-db/mock'

const dataRateReport = [
  {
    "advertiser": "Stackt_mobidriven",
    "campaignId": 11376,
    "campaignName": "11376 // Stackt_UK // 1 месяц с даты старта // 331к показов на каждый сегмент",
    "unitType": "impressions",
    "percent": -68.25,
    "fact": 216380,
    "factOfTheLastDay": 16293,
    "plan": 662000,
    "prognosis": 315276,
    "unitsPerDayNeed": 55702,
    "daysLeft": 8,
    "statDateStop": "2022-11-23T00:00:00Z",
    "campaignDateStop": "2022-11-30T20:59:00Z"
  },
  {
    "advertiser": "АТБ_mobidriven",
    "campaignId": 11378,
    "campaignName": "11378 // IVK Media//АТБ//10.10-30.11// 11 400 кликов",
    "unitType": "clicks",
    "percent": -9314.29,
    "fact": 6409,
    "factOfTheLastDay": 645,
    "plan": 5700,
    "prognosis": -5869,
    "unitsPerDayNeed": -88,
    "daysLeft": 8,
    "statDateStop": "2022-11-23T00:00:00Z",
    "campaignDateStop": "2022-11-30T20:59:00Z"
  },
]

mock.onGet('/rate-report/data').reply(() => [200, dataRateReport])
