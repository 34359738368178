import main from './main'

// Array of sections
// export default [...dashboard, ...appAndPages, ...uiElements, ...formsAndTables, ...chartsAndMaps, ...others]
export default () => {
  const getMenuItems = () => {
    const { getMainItems } = main()

    return getMainItems()
  }

  return {
    getMenuItems,
  }
}
