const apps = [
  {
    path: '/pp-control',
    name: 'pp-control',
    component: () => import('@/views/mobidriven/TrackingCode/TrackingCode.vue'),
    meta: {
      layout: 'content',
      name: 'PPControl',
      resource: 'TRACKING_PIXEL',
    },
  },
  {
    path: '/rate-report',
    name: 'rate-report',
    component: () => import('@/views/mobidriven/RateReport/RateReport.vue'),
    meta: {
      layout: 'content',
      name: 'RateReport',
      resource: 'REPORT_RATE',
    },
  },
  {
    path: '/vtr-report',
    name: 'vtr-report',
    component: () => import('@/views/mobidriven/VtrReport/VtrReport.vue'),
    meta: {
      layout: 'content',
      name: 'VTRReport',
      resource: 'REPORT_VTR',
    },
  },
  {
    path: '/publishers',
    name: 'advertisers',
    component: () => import('@/views/mobidriven/Advertisers/AdvertisersPage.vue'),
    meta: {
      layout: 'content',
      name: 'Advertisers',
      resource: 'Public',
      availableFor: ['ROOT', 'SUPER_ADMINS', 'MANAGER_ADMINS', 'MANAGERS'],
    },
  },
  {
    path: '/generate-report/:report_id?',
    name: 'advertisers-generate-report',
    component: () => import('@/views/mobidriven/AdvertisersReportGeneration/AdvertisersReportGenerationPage.vue'),
    meta: {
      layout: 'content',
      name: 'AdvertisersGenerateReport',
      resource: 'REPORTS_GENERATION',
      availableFor: ['ROOT', 'SUPER_ADMINS', 'MANAGER_ADMINS', 'MANAGERS'],
    },
  },
  {
    path: '/campaigns/:advertiser_id?/list',
    name: 'campaigns-list',
    component: () => import('@/views/mobidriven/CampaignsList/CampaignsListPage.vue'),
    meta: {
      layout: 'content',
      name: 'CampaignsList',
      resource: 'Public',
    },
  },
  {
    path: '/campaign/:campaign_id?/statistics',
    name: 'campaigns-statistics',
    component: () => import('@/views/mobidriven/CampaignStatistics/CampaignStatisticsPage.vue'),
    meta: {
      layout: 'content',
      name: 'CampaignStatistics',
      resource: 'REPORT_CAMPAIGN_STATISTICS',
    },
  },
  {
    path: '/campaign/:campaign_id?/geo',
    name: 'campaigns-geo',
    component: () => import('@/views/mobidriven/geo/Geo.vue'),
    meta: {
      layout: 'content',
      name: 'CampaignGeo',
      resource: 'REPORT_CITY',
    },
  },
  {
    path: '/campaign/:campaign_id?/auditory',
    name: 'campaigns-auditory',
    component: () => import('@/views/mobidriven/Audience/Audience.vue'),
    meta: {
      layout: 'content',
      name: 'CampaignAuditory',
      resource: 'REPORT_AUDIENCE_DEMOGRAPHY',
    },
  },
  {
    path: '/campaign/:campaign_id?/creatives',
    name: 'campaigns-creatives',
    component: () => import('@/views/mobidriven/CampaignCreativesList/CampaignCreativesListPage.vue'),
    meta: {
      layout: 'content',
      name: 'CampaingCreatives',
      resource: 'REPORT_CREATIVES',
    },
  },
  {
    path: '/campaign/creativ/:creative_id/statistics',
    name: 'creatives-statistics',
    component: () => import('@/views/mobidriven/CampaignCreativesDaily/CampaignCreativesDailyPage.vue'),
    meta: {
      layout: 'content',
      name: 'CampaingCreativesDaily',
      resource: 'REPORT_CREATIVES',
    },
  },
  {
    path: '/campaign/creativ/:creative_id/vtr-report',
    name: 'creatives-vtr-report',
    component: () => import('@/views/mobidriven/CampaignCreativesVTR/CampaignCreativesVTRPage.vue'),
    meta: {
      layout: 'content',
      name: 'CampaingCreativesVTR',
      resource: 'REPORT_CREATIVES',
    },
  },
  {
    path: '/campaign/:campaign_id?/providers',
    name: 'campaigns-providers',
    component: () => import('@/views/mobidriven/Providers/Providers.vue'),
    meta: {
      layout: 'content',
      name: 'CampaingProvidersReport',
      resource: 'REPORT_PROVIDERS',
    },
  },
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/mobidriven/UsersList/UsersListPage.vue'),
    meta: {
      layout: 'content',
      name: 'Users',
      resource: 'USERS',
    },
  },
  {
    path: '/user/create',
    name: 'user-create',
    component: () => import('@/views/mobidriven/UserCreate/UserCreatePage.vue'),
    meta: {
      layout: 'content',
      name: 'UserPage.UserCreation',
      resource: 'USER_CREATE',
    },
  },
  {
    path: '/user/edit/:user_id',
    name: 'user-edit',
    component: () => import('@/views/mobidriven/UserEdit/UserEditPage.vue'),
    meta: {
      layout: 'content',
      name: 'UserPage.UserEdit',
      resource: 'USER_UPDATE',
    },
  },
  {
    path: '/users/link/:user_id?',
    name: 'users-link',
    component: () => import('@/views/mobidriven/UsersLink/UsersLinkPage.vue'),
    meta: {
      layout: 'content',
      name: 'LinkUser',
      resource: 'LINK_USERS',
    },
  },
  {
    path: '/profile/edit',
    name: 'profile-edit',
    component: () => import('@/views/mobidriven/UserProfile/UserProfilePage.vue'),
    meta: {
      layout: 'content',
      name: 'ProfilePage.Profile',
      resource: 'Public',
    },
  },
]

export default apps
