import mock from '@/@fake-db/mock'

const data = [
  {
    "advertiser": "Skoda",
    "creativeName": "video_20sec",
    "num25": 488392,
    "viewTo75": 400295,
    "viewTo25": 488392,
    "creativeId": 33646,
    "num50": 446076,
    "campaignName": "Skoda // Rapid // may-jul 2020 // video // WL // 200 000 imp",
    "viewTo50": 446076,
    "campaignId": 7217,
    "views": 569473,
    "viewTo100": 368285,
    "num75": 400295,
    "num100": 368285
  },
  {
    "advertiser": "Эльдорадо",
    "creativeName": "BEZTOVARA_2mb_21s_mp4",
    "num25": 1263658,
    "viewTo75": 1024261,
    "viewTo25": 1263658,
    "creativeId": 38920,
    "num50": 1143500,
    "campaignName": "8146 // Эльдорадо // Утилизация // MDP Video // 29.09 - 02.11 // 5 200 000 показов",
    "viewTo50": 1143500,
    "campaignId": 8146,
    "views": 1484950,
    "viewTo100": 951299,
    "num75": 1024261,
    "num100": 951299
  },
  {
    "advertiser": "Эльдорадо",
    "creativeName": "BOSCH_2mb_27s_mp4",
    "num25": 462039,
    "viewTo75": 356545,
    "viewTo25": 462039,
    "creativeId": 38921,
    "num50": 401389,
    "campaignName": "8146 // Эльдорадо // Утилизация // MDP Video // 29.09 - 02.11 // 5 200 000 показов",
    "viewTo50": 401389,
    "campaignId": 8146,
    "views": 521422,
    "viewTo100": 313858,
    "num75": 356545,
    "num100": 313858
  },
]

const advertisers = [
  {
    id: 1,
    name: 'Galen Slixby',
    company: 'Yotz PVT LTD',
    role: 'editor',
    username: 'gslixby0',
    country: 'El Salvador',
    contact: '(479) 232-9151',
    email: 'gslixby0@abc.net.au',
    currentPlan: 'enterprise',
    status: 'inactive',
    avatar: '',
  },
  {
    id: 2,
    name: 'Halsey Redmore',
    company: 'Skinder PVT LTD',
    role: 'author',
    username: 'hredmore1',
    country: 'Albania',
    contact: '(472) 607-9137',
    email: 'hredmore1@imgur.com',
    currentPlan: 'standard',
    status: 'pending',
    avatar: '8.png',
  },
]

mock.onGet('/vtr-report/data').reply(() => [200, data])

mock.onGet('/user/advertisers').reply(() => [200, advertisers])
