import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{key:_vm.userData.name || _vm.userData.avatar,attrs:{"offset-y":"","left":"","nudge-bottom":"14","min-width":"230","content-class":"user-profile-menu-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-flex flex-column justify-center ms-3",staticStyle:{"vertical-align":"middle"}},'div',attrs,false),on),[_c(VRow,{staticClass:"mr-4 ml-3"},[_c(VCol,{attrs:{"cols":"9","md":"9","sm":"9"}},[_c('span',{staticClass:"text--primary font-weight-semibold mb-n1"},[_vm._v(" "+_vm._s(_vm.userData.name || _vm.userData.username)+", ")]),_c('br'),_c('small',{staticClass:"text--disabled text-capitalize"},[_vm._v(_vm._s(_vm.$t('Welcome')))])]),_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"3","md":"3","sm":"3"}},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"50px","color":"primary"}},[(_vm.userData.avatarBase64)?_c(VImg,{attrs:{"src":_vm.userData.avatarBase64}}):_c(VIcon,{attrs:{"color":"primary","size":"28"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountOutline)+" ")])],1)],1)],1)],1)]}}])},[_c(VList,[_c(VListItem,{attrs:{"to":{ name: 'profile-edit'}}},[_c(VListItemIcon,{staticClass:"me-2"},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountOutline)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('Profile')))])],1)],1),_c(VListItem,{on:{"click":_vm.logoutUser}},[_c(VListItemIcon,{staticClass:"me-2"},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiLogoutVariant)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('Logout')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }