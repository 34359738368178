import mock from '@/@fake-db/mock'

const dataCampaignStatistics = {
  daily: [
    {
      id: 7197,
      name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
      is_video: false,
      model_bet_stat: 0,
      statistics: [
        {
          rf: 2.16,
          ctr: 4,
          viewability: 0,
          cost: 0,
          uniq_count: 23,
          date_string: '01-11-2022',
          ref_count: 1,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 25,
          coast: 0,
        },
        {
          rf: 2.16,
          ctr: 0.51,
          viewability: 0,
          cost: 0,
          uniq_count: 96631,
          date_string: '31-10-2022',
          uniq_count_period: 0,
          ref_count: 613,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 120061,
          coast: 0,
        },
        {
          rf: 2.16,
          ctr: 0.55,
          viewability: 0,
          cost: 0,
          uniq_count: 17964,
          date_string: '30-10-2022',
          uniq_count_period: 0,
          ref_count: 144,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 26021,
          coast: 0,
        },
      ],
    },
    {
      id: 7228,
      name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
      is_video: true,
      model_bet_stat: 1,
      statistics: [
        {
          rf: 2.16,
          ctr: 1.81,
          viewability: 0,
          cost: 0,
          uniq_count: 5951,
          date_string: '29-10-2022',
          uniq_count_period: 0,
          ref_count: 134,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 7366,
          coast: 0,
        },
        {
          rf: 2.16,
          ctr: 1.61,
          viewability: 0,
          cost: 0,
          uniq_count: 5129,
          date_string: '28-10-2022',
          uniq_count_period: 0,
          ref_count: 95,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 5873,
          coast: 0,
        },
      ],
    },
    {
      id: 477,
      name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
      is_video: false,
      model_bet_stat: 0,
      statistics: [
        {
          rf: 2.16,
          ctr: 0.76,
          viewability: 0,
          cost: 0,
          uniq_count: 47708,
          date_string: '27-10-2022',
          uniq_count_period: 0,
          ref_count: 488,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 63853,
          coast: 0,
        },
      ],
    },
    {
      id: 479,
      name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
      is_video: true,
      model_bet_stat: 1,
      statistics: [
        {
          rf: 2.16,
          ctr: 0.82,
          viewability: 0,
          cost: 0,
          uniq_count: 45887,
          date_string: '26-10-2022',
          uniq_count_period: 0,
          ref_count: 480,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 58516,
          coast: 0,
        },
      ],
    },
  ],
  weekly: [
    {
      id: 7197,
      name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
      is_video: false,
      model_bet_stat: 0,
      statistics: [
        {
          rf: 2.16,
          ctr: 4,
          viewability: 0,
          cost: 0,
          uniq_count: 23,
          date_string: '31-10-2022 / 06-11-2022',
          uniq_count_period: 0,
          ref_count: 1,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 25,
          coast: 0,
        },
      ],
    },
    {
      id: 7228,
      name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
      is_video: true,
      model_bet_stat: 1,
      statistics: [
        {
          rf: 2.16,
          ctr: 0.51,
          viewability: 0,
          cost: 0,
          uniq_count: 96631,
          date_string: '31-10-2022 / 06-11-2022',
          uniq_count_period: 0,
          ref_count: 613,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 120061,
          coast: 0,
        },
      ],
    },
    {
      id: 477,
      name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
      is_video: false,
      model_bet_stat: 0,
      statistics: [
        {
          rf: 2.16,
          ctr: 0.55,
          viewability: 0,
          cost: 0,
          uniq_count: 17964,
          date_string: '31-10-2022 / 06-11-2022',
          uniq_count_period: 0,
          ref_count: 144,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 26021,
          coast: 0,
        },
      ],
    },
    {
      id: 479,
      name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
      is_video: true,
      model_bet_stat: 1,
      statistics: [
        {
          rf: 2.16,
          ctr: 1.81,
          viewability: 0,
          cost: 0,
          uniq_count: 5951,
          date_string: '31-10-2022 / 06-11-2022',
          uniq_count_period: 0,
          ref_count: 134,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 7366,
          coast: 0,
        },
      ],
    },
  ],
}

const dataCampaignStatisticsExcel = {
  daily: [
    {
      id: 7197,
      name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
      is_video: false,
      model_bet_stat: 0,
      total_current_period: {
        rf: 2.16,
        ctr: 4,
        viewability: 0,
        cost: 0,
        uniq_count: 23,
        ref_count: 1,
        interactions: 0,
        video_views: 0,
        fraud: 0,
        coast_per_unit: 0,
        pi: 0,
        show_count: 25,
        coast: 0,
      },
      total_all_period: {
        rf: 2.16,
        ctr: 4,
        viewability: 0,
        cost: 0,
        uniq_count: 23,
        ref_count: 1,
        interactions: 0,
        video_views: 0,
        fraud: 0,
        coast_per_unit: 0,
        pi: 0,
        show_count: 25,
        coast: 0,
      },
      statistics: [
        {
          rf: 2.16,
          ctr: 4,
          viewability: 0,
          cost: 0,
          uniq_count: 23,
          date_string: '01-11-2022',
          ref_count: 1,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 25,
          coast: 0,
        },
        {
          rf: 2.16,
          ctr: 0.51,
          viewability: 0,
          cost: 0,
          uniq_count: 96631,
          date_string: '31-10-2022',
          uniq_count_period: 0,
          ref_count: 613,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 120061,
          coast: 0,
        },
        {
          rf: 2.16,
          ctr: 0.55,
          viewability: 0,
          cost: 0,
          uniq_count: 17964,
          date_string: '30-10-2022',
          uniq_count_period: 0,
          ref_count: 144,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 26021,
          coast: 0,
        },
      ],
    },
    {
      id: 7228,
      name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
      is_video: true,
      model_bet_stat: 1,
      total_current_period: {
        rf: 2.16,
        ctr: 1.81,
        viewability: 0,
        cost: 0,
        uniq_count: 5951,
        uniq_count_period: 0,
        ref_count: 134,
        interactions: 0,
        video_views: 0,
        fraud: 0,
        coast_per_unit: 0,
        pi: 0,
        show_count: 7366,
        coast: 0,
      },
      total_all_period: {
        rf: 2.16,
        ctr: 1.81,
        viewability: 0,
        cost: 0,
        uniq_count: 5951,
        uniq_count_period: 0,
        ref_count: 134,
        interactions: 0,
        video_views: 0,
        fraud: 0,
        coast_per_unit: 0,
        pi: 0,
        show_count: 7366,
        coast: 0,
      },
      statistics: [
        {
          rf: 2.16,
          ctr: 1.81,
          viewability: 0,
          cost: 0,
          uniq_count: 5951,
          date_string: '29-10-2022',
          uniq_count_period: 0,
          ref_count: 134,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 7366,
          coast: 0,
        },
        {
          rf: 2.16,
          ctr: 1.61,
          viewability: 0,
          cost: 0,
          uniq_count: 5129,
          date_string: '28-10-2022',
          uniq_count_period: 0,
          ref_count: 95,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 5873,
          coast: 0,
        },
      ],
    },
    {
      id: 477,
      name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
      is_video: false,
      model_bet_stat: 0,
      total_current_period: {
        rf: 2.16,
        ctr: 1.81,
        viewability: 0,
        cost: 0,
        uniq_count: 5951,
        uniq_count_period: 0,
        ref_count: 134,
        interactions: 0,
        video_views: 0,
        fraud: 0,
        coast_per_unit: 0,
        pi: 0,
        show_count: 7366,
        coast: 0,
      },
      total_all_period: {
        rf: 2.16,
        ctr: 1.81,
        viewability: 0,
        cost: 0,
        uniq_count: 5951,
        uniq_count_period: 0,
        ref_count: 134,
        interactions: 0,
        video_views: 0,
        fraud: 0,
        coast_per_unit: 0,
        pi: 0,
        show_count: 7366,
        coast: 0,
      },
      statistics: [
        {
          rf: 2.16,
          ctr: 0.76,
          viewability: 0,
          cost: 0,
          uniq_count: 47708,
          date_string: '27-10-2022',
          uniq_count_period: 0,
          ref_count: 488,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 63853,
          coast: 0,
        },
      ],
    },
    {
      id: 479,
      name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
      is_video: true,
      model_bet_stat: 1,
      total_current_period: {
        rf: 2.16,
        ctr: 1.81,
        viewability: 0,
        cost: 0,
        uniq_count: 5951,
        uniq_count_period: 0,
        ref_count: 134,
        interactions: 0,
        video_views: 0,
        fraud: 0,
        coast_per_unit: 0,
        pi: 0,
        show_count: 7366,
        coast: 0,
      },
      total_all_period: {
        rf: 2.16,
        ctr: 1.81,
        viewability: 0,
        cost: 0,
        uniq_count: 5951,
        uniq_count_period: 0,
        ref_count: 134,
        interactions: 0,
        video_views: 0,
        fraud: 0,
        coast_per_unit: 0,
        pi: 0,
        show_count: 7366,
        coast: 0,
      },
      statistics: [
        {
          rf: 2.16,
          ctr: 0.82,
          viewability: 0,
          cost: 0,
          uniq_count: 45887,
          date_string: '26-10-2022',
          uniq_count_period: 0,
          ref_count: 480,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 58516,
          coast: 0,
        },
      ],
    },
  ],
  weekly: [
    {
      id: 7197,
      name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
      is_video: false,
      model_bet_stat: 0,
      total_current_period: {
        rf: 2.16,
        ctr: 1.81,
        viewability: 0,
        cost: 0,
        uniq_count: 5951,
        uniq_count_period: 0,
        ref_count: 134,
        interactions: 0,
        video_views: 0,
        fraud: 0,
        coast_per_unit: 0,
        pi: 0,
        show_count: 7366,
        coast: 0,
      },
      total_all_period: {
        rf: 2.16,
        ctr: 1.81,
        viewability: 0,
        cost: 0,
        uniq_count: 5951,
        uniq_count_period: 0,
        ref_count: 134,
        interactions: 0,
        video_views: 0,
        fraud: 0,
        coast_per_unit: 0,
        pi: 0,
        show_count: 7366,
        coast: 0,
      },
      statistics: [
        {
          rf: 2.16,
          ctr: 4,
          viewability: 0,
          cost: 0,
          uniq_count: 23,
          date_string: '31-10-2022 / 06-11-2022',
          uniq_count_period: 0,
          ref_count: 1,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 25,
          coast: 0,
        },
      ],
    },
    {
      id: 7228,
      name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
      is_video: true,
      model_bet_stat: 1,
      total_current_period: {
        rf: 2.16,
        ctr: 1.81,
        viewability: 0,
        cost: 0,
        uniq_count: 5951,
        uniq_count_period: 0,
        ref_count: 134,
        interactions: 0,
        video_views: 0,
        fraud: 0,
        coast_per_unit: 0,
        pi: 0,
        show_count: 7366,
        coast: 0,
      },
      total_all_period: {
        rf: 2.16,
        ctr: 1.81,
        viewability: 0,
        cost: 0,
        uniq_count: 5951,
        uniq_count_period: 0,
        ref_count: 134,
        interactions: 0,
        video_views: 0,
        fraud: 0,
        coast_per_unit: 0,
        pi: 0,
        show_count: 7366,
        coast: 0,
      },
      statistics: [
        {
          rf: 2.16,
          ctr: 0.51,
          viewability: 0,
          cost: 0,
          uniq_count: 96631,
          date_string: '31-10-2022 / 06-11-2022',
          uniq_count_period: 0,
          ref_count: 613,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 120061,
          coast: 0,
        },
      ],
    },
    {
      id: 477,
      name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
      is_video: false,
      model_bet_stat: 0,
      total_current_period: {
        rf: 2.16,
        ctr: 1.81,
        viewability: 0,
        cost: 0,
        uniq_count: 5951,
        uniq_count_period: 0,
        ref_count: 134,
        interactions: 0,
        video_views: 0,
        fraud: 0,
        coast_per_unit: 0,
        pi: 0,
        show_count: 7366,
        coast: 0,
      },
      total_all_period: {
        rf: 2.16,
        ctr: 1.81,
        viewability: 0,
        cost: 0,
        uniq_count: 5951,
        uniq_count_period: 0,
        ref_count: 134,
        interactions: 0,
        video_views: 0,
        fraud: 0,
        coast_per_unit: 0,
        pi: 0,
        show_count: 7366,
        coast: 0,
      },
      statistics: [
        {
          rf: 2.16,
          ctr: 0.55,
          viewability: 0,
          cost: 0,
          uniq_count: 17964,
          date_string: '31-10-2022 / 06-11-2022',
          uniq_count_period: 0,
          ref_count: 144,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 26021,
          coast: 0,
        },
      ],
    },
    {
      id: 479,
      name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
      is_video: true,
      model_bet_stat: 1,
      total_current_period: {
        rf: 2.16,
        ctr: 1.81,
        viewability: 0,
        cost: 0,
        uniq_count: 5951,
        uniq_count_period: 0,
        ref_count: 134,
        interactions: 0,
        video_views: 0,
        fraud: 0,
        coast_per_unit: 0,
        pi: 0,
        show_count: 7366,
        coast: 0,
      },
      total_all_period: {
        rf: 2.16,
        ctr: 1.81,
        viewability: 0,
        cost: 0,
        uniq_count: 5951,
        uniq_count_period: 0,
        ref_count: 134,
        interactions: 0,
        video_views: 0,
        fraud: 0,
        coast_per_unit: 0,
        pi: 0,
        show_count: 7366,
        coast: 0,
      },
      statistics: [
        {
          rf: 2.16,
          ctr: 1.81,
          viewability: 0,
          cost: 0,
          uniq_count: 5951,
          date_string: '31-10-2022 / 06-11-2022',
          uniq_count_period: 0,
          ref_count: 134,
          interactions: 0,
          model_bet_stat: 1,
          video_views: 0,
          fraud: 0,
          coast_per_unit: 0,
          pi: 0,
          show_count: 7366,
          coast: 0,
        },
      ],
    },
  ],
}

const dataWidgets = {
  shows: {
    period_count: 3214,
    total_count: 32146,
  },
  references: {
    period_count: 1243,
    total_count: 23543,
  },
  coverage: {
    period_count: 2987,
    total_count: 30546,
  },
  ctr: {
    period_count: 0.15,
    total_count: 0.19,
  },
  rf: {
    period_count: 2.81,
    total_count: 2.92,
  },
  viewability: {
    period_count: 2.81,
    total_count: 2.92,
  },
  fraud: {
    period_count: 2.81,
    total_count: 2.92,
  },
  pi: {
    period_count: 2.81,
    total_count: 2.92,
  },
  interactions: {
    period_count: 3214,
    total_count: 32143,
  },
  views: {
    period_count: 3236,
    total_count: 65542,
  },
  coast: {
    period_count: 32134.32,
    total_count: 3214331.12,
  },
}

const dataBrandSafty = {
  safty_level: 4,
  words_list: [ // Возвращает список слов текущего и всех ниже стоящих уровней безопасности в одном массиве.
    { word: 'Сиська' },
    { word: 'Писька' },
    { word: 'Хвост' },
  ],

}

const dataChart = {
  daily: {
    series: [
      {
        name: 'Shows',
        data: [1234, 1334, 1343, 4345, 3453, 4534, 3453, 4533, 5634, 3456, 7842, 2543, 1533],
      },
      {
        name: 'References',
        data: [60, 80, 70, 110, 80, 100, 90, 180, 160, 140, 200, 220, 275],
      },
      {
        name: 'Сoverage',
        data: [1021, 1230, 1124, 3365, 2354, 3654, 2314, 3254, 4214, 2365, 6541, 1254, 642],
      },
    ],
    categories: [
      '15-10',
      '16-10',
      '17-10',
      '18-10',
      '19-10',
      '20-10',
      '21-10',
      '22-10',
      '23-10',
      '24-10',
      '25-10',
      '26-10',
      '27-10',
    ],
  },
  weekly: {
    series: [
      {
        name: 'Shows',
        data: [12234, 13334],
      },
      {
        name: 'References',
        data: [550, 636],
      },
      {
        name: 'Сoverage',
        data: [13021, 18230],
      },
    ],
    categories: [
      '15-10 / 21-10',
      '22-10 / 27-10',
    ],
  },
}

mock.onGet('/campaign-statistics/table_data').reply(request => {
  // eslint-disable-next-line camelcase
  const { is_weekly, campaign_id } = request.params
  // eslint-disable-next-line camelcase
  const data = is_weekly ? dataCampaignStatistics.weekly.find(c => c.id === campaign_id) : dataCampaignStatistics.daily.find(c => c.id === campaign_id)

  return [200, data]
})

mock.onGet('/campaign-statistics/excel_data').reply(request => {
  // eslint-disable-next-line camelcase
  const { is_weekly, campaign_id } = request.params
  // eslint-disable-next-line camelcase
  const data = is_weekly ? dataCampaignStatisticsExcel.weekly.find(c => c.id === campaign_id) : dataCampaignStatisticsExcel.daily.find(c => c.id === campaign_id)

  return [200, data]
})

mock.onGet('/campaign-statistics/brand-safty-data').reply(() => [200, dataBrandSafty])
mock.onGet('/campaign-statistics/widgets-data').reply(() => [200, dataWidgets])

mock.onGet('/campaign-statistics/chart-data').reply(request => {
  // eslint-disable-next-line camelcase
  const { is_weekly } = request.params
  // eslint-disable-next-line camelcase
  const data = is_weekly ? dataChart.weekly : dataChart.daily

  // console.log(campaigns)

  return [200, data]
})
