import mock from '@/@fake-db/mock'

const dataCreatives = [
  {
    campaign_id: 7197,
    is_video: true,
    total_current_period:
    {
      ctr: 1.82,
      uniq_count: 1003222,
      ref_count: 22949,
      unique_clicks: 22070,
      show_count: 1219219,
    },
    total_all_period: {
      ctr: 1.93,
      uniq_count: 1033222,
      ref_count: 622949,
      unique_clicks: 622070,
      show_count: 5319219,
    },
    creative_list: [
      {
        ctr: 1.82,
        uniq_count: 1003222,
        ref_count: 22949,
        creative_id: 50720,
        unique_clicks: 22070,
        name: 'olv_20sec',
        show_count: 1219219,
        campaign_id: 7197,
      },
      {
        ctr: 1.82,
        uniq_count: 1003222,
        ref_count: 3424,
        creative_id: 50721,
        unique_clicks: 22070,
        name: 'olv_10sec',
        show_count: 1519219,
        campaign_id: 7197,
      },
      {
        ctr: 1.82,
        uniq_count: 1003222,
        ref_count: 3424,
        creative_id: 50722,
        unique_clicks: 22070,
        name: 'olv_30sec',
        show_count: 1119239,
        campaign_id: 7197,
      },
      {
        ctr: 1.82,
        uniq_count: 1003222,
        ref_count: 3424,
        creative_id: 50723,
        unique_clicks: 22070,
        name: 'olv_5sec',
        show_count: 12192,
        campaign_id: 7197,
      },
    ],
  },
  {
    campaign_id: 7228,
    is_video: false,
    total_current_period:
    {
      ctr: 1.82,
      uniq_count: 1003222,
      ref_count: 22949,
      unique_clicks: 22070,
      show_count: 1219219,
    },
    total_all_period: {
      ctr: 1.93,
      uniq_count: 1033222,
      ref_count: 622949,
      unique_clicks: 622070,
      show_count: 5319219,
    },
    creative_list: [
      {
        ctr: 1.82,
        uniq_count: 1003222,
        ref_count: 22949,
        creative_id: 50730,
        unique_clicks: 22070,
        name: 'olv_20sec',
        show_count: 1219219,
        campaign_id: 7228,
      },
    ],
  },
  {
    campaign_id: 7228,
    is_video: false,
    total_current_period:
    {
      ctr: 1.82,
      uniq_count: 1003222,
      ref_count: 22949,
      unique_clicks: 22070,
      show_count: 1219219,
    },
    total_all_period: {
      ctr: 1.93,
      uniq_count: 1033222,
      ref_count: 622949,
      unique_clicks: 622070,
      show_count: 5319219,
    },
    creative_list: [
      {
        ctr: 1.82,
        uniq_count: 1003222,
        ref_count: 22949,
        creative_id: 50731,
        unique_clicks: 22070,
        name: 'olv_20sec',
        show_count: 1219219,
        campaign_id: 7228,
      },
    ],
  },
  {
    campaign_id: 477,
    is_video: false,
    total_current_period:
    {
      ctr: 1.82,
      uniq_count: 1003222,
      ref_count: 22949,
      unique_clicks: 22070,
      show_count: 1219219,
    },
    total_all_period: {
      ctr: 1.93,
      uniq_count: 1033222,
      ref_count: 622949,
      unique_clicks: 622070,
      show_count: 5319219,
    },
    creative_list: [
      {
        ctr: 1.82,
        uniq_count: 1003222,
        ref_count: 22949,
        creative_id: 50720,
        unique_clicks: 22070,
        name: 'olv_20sec',
        show_count: 1219219,
        campaign_id: 477,
      },
    ],
  },
  {
    campaign_id: 479,
    is_video: true,
    total_current_period:
    {
      ctr: 1.82,
      uniq_count: 1003222,
      ref_count: 22949,
      unique_clicks: 22070,
      show_count: 1219219,
    },
    total_all_period: {
      ctr: 1.93,
      uniq_count: 1033222,
      ref_count: 622949,
      unique_clicks: 622070,
      show_count: 5319219,
    },
    creative_list: [
      {
        ctr: 1.82,
        uniq_count: 1003222,
        ref_count: 22949,
        creative_id: 50720,
        unique_clicks: 22070,
        name: 'olv_20sec',
        show_count: 1219219,
        campaign_id: 479,
      },
    ],
  },
]

const dataCreativesChart = {
  labels: ['olv_20sec', 'olv_10sec', 'olv_30sec', 'olv_5sec', 'olv_60sec'], // Это пол. Его скорее всего нужно будет перевести. Поэтому тут скорее всего будут ключи из i18n
  series: [1219219, 1519219, 1119239, 121922, 109239],
}

const dataExcelCreatives = {
  summary_list: {
    campaign_id: 7197,
    campaign_name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
    total_current_period:
      {
        ctr: 1.82,
        uniq_count: 1003222,
        ref_count: 22949,
        unique_clicks: 22070,
        rf: 1.23,
        show_count: 1219219,
      },
    total_all_period:
      {
        ctr: 1.93,
        uniq_count: 1033222,
        ref_count: 622949,
        unique_clicks: 622070,
        rf: 1.80,
        show_count: 5319219,
      },
    creative_list: [
      {
        name: 'olv_20sec',
        show_count: 1219219,
        ref_count: 22949,
        uniq_count: 1003222,
        rf: 1.23,
        ctr: 1.82,
      },
      {
        name: 'olv_10sec',
        show_count: 1519219,
        ref_count: 3424,
        uniq_count: 1003222,
        rf: 1.23,
        ctr: 1.82,
      },
      {
        name: 'olv_30sec',
        show_count: 1119239,
        ref_count: 3424,
        uniq_count: 1003222,
        rf: 1.23,
        ctr: 1.82,
      },
      {
        name: 'olv_5sec',
        show_count: 12192,
        ref_count: 3424,
        uniq_count: 1003222,
        rf: 1.23,
        ctr: 1.82,
      },
    ],
  },
  creatives_statistics: [
    {
      creative_id: 50720,
      name: 'olv_20sec',
      total_current_period:
        {
          ctr: 1.82,
          uniq_count: 1003222,
          ref_count: 22949,
          unique_clicks: 22070,
          rf: 1.23,
          show_count: 1219219,
        },
      total_all_period:
        {
          ctr: 1.93,
          uniq_count: 1033222,
          ref_count: 622949,
          unique_clicks: 622070,
          rf: 1.80,
          show_count: 5319219,
        },
      statistics: [
        {
          date_string: '01-11-2022',
          ctr: 2.31,
          uniq_count: 99661,
          ref_count: 2886,
          unique_clicks: 2784,
          rf: 1.23,
          show_count: 124441,
        },
        {
          date_string: '02-11-2022',
          ctr: 1.80,
          uniq_count: 99354,
          ref_count: 2248,
          unique_clicks: 2162,
          rf: 1.23,
          show_count: 124320,
        },
        {
          date_string: '03-11-2022',
          ctr: 1.07,
          uniq_count: 106519,
          ref_count: 1330,
          unique_clicks: 1287,
          rf: 1.23,
          show_count: 123967,
        },
        {
          date_string: '04-11-2022',
          ctr: 2.88,
          uniq_count: 97939,
          ref_count: 3602,
          unique_clicks: 3443,
          rf: 1.23,
          show_count: 124809,
        },
      ],
    },
    {
      creative_id: 50721,
      name: 'olv_10sec',
      total_current_period:
        {
          ctr: 1.82,
          uniq_count: 1003222,
          ref_count: 22949,
          unique_clicks: 22070,
          rf: 1.23,
          show_count: 1219219,
        },
      total_all_period:
        {
          ctr: 1.93,
          uniq_count: 1033222,
          ref_count: 622949,
          unique_clicks: 622070,
          rf: 1.80,
          show_count: 5319219,
        },
      statistics: [
        {
          date_string: '01-11-2022',
          ctr: 2.31,
          uniq_count: 99661,
          ref_count: 2886,
          unique_clicks: 2784,
          rf: 1.23,
          show_count: 124441,
        },
        {
          date_string: '02-11-2022',
          ctr: 1.80,
          uniq_count: 99354,
          ref_count: 2248,
          unique_clicks: 2162,
          rf: 1.23,
          show_count: 124320,
        },
        {
          date_string: '03-11-2022',
          ctr: 1.07,
          uniq_count: 106519,
          ref_count: 1330,
          unique_clicks: 1287,
          rf: 1.23,
          show_count: 123967,
        },
        {
          date_string: '04-11-2022',
          ctr: 2.88,
          uniq_count: 97939,
          ref_count: 3602,
          unique_clicks: 3443,
          rf: 1.23,
          show_count: 124809,
        },
      ],
    },
    {
      creative_id: 50722,
      name: 'olv_30sec',
      total_current_period:
        {
          ctr: 1.82,
          uniq_count: 1003222,
          ref_count: 22949,
          unique_clicks: 22070,
          rf: 1.23,
          show_count: 1219219,
        },
      total_all_period:
        {
          ctr: 1.93,
          uniq_count: 1033222,
          ref_count: 622949,
          unique_clicks: 622070,
          rf: 1.80,
          show_count: 5319219,
        },
      statistics: [
        {
          date_string: '01-11-2022',
          ctr: 2.31,
          uniq_count: 99661,
          ref_count: 2886,
          unique_clicks: 2784,
          rf: 1.23,
          show_count: 124441,
        },
        {
          date_string: '02-11-2022',
          ctr: 1.80,
          uniq_count: 99354,
          ref_count: 2248,
          unique_clicks: 2162,
          rf: 1.23,
          show_count: 124320,
        },
        {
          date_string: '03-11-2022',
          ctr: 1.07,
          uniq_count: 106519,
          ref_count: 1330,
          unique_clicks: 1287,
          rf: 1.23,
          show_count: 123967,
        },
        {
          date_string: '04-11-2022',
          ctr: 2.88,
          uniq_count: 97939,
          ref_count: 3602,
          unique_clicks: 3443,
          rf: 1.23,
          show_count: 124809,
        },
      ],
    },
    {
      creative_id: 50723,
      name: 'olv_5sec',
      total_current_period:
        {
          ctr: 1.82,
          uniq_count: 1003222,
          ref_count: 22949,
          unique_clicks: 22070,
          rf: 1.23,
          show_count: 1219219,
        },
      total_all_period:
        {
          ctr: 1.93,
          uniq_count: 1033222,
          ref_count: 622949,
          unique_clicks: 622070,
          rf: 1.80,
          show_count: 5319219,
        },
      statistics: [
        {
          date_string: '01-11-2022',
          ctr: 2.31,
          uniq_count: 99661,
          ref_count: 2886,
          unique_clicks: 2784,
          rf: 1.23,
          show_count: 124441,
        },
        {
          date_string: '02-11-2022',
          ctr: 1.80,
          uniq_count: 99354,
          ref_count: 2248,
          unique_clicks: 2162,
          rf: 1.23,
          show_count: 124320,
        },
        {
          date_string: '03-11-2022',
          ctr: 1.07,
          uniq_count: 106519,
          ref_count: 1330,
          unique_clicks: 1287,
          rf: 1.23,
          show_count: 123967,
        },
        {
          date_string: '04-11-2022',
          ctr: 2.88,
          uniq_count: 97939,
          ref_count: 3602,
          unique_clicks: 3443,
          rf: 1.23,
          show_count: 124809,
        },
      ],
    },
  ],
  summary_creatives_statistics: {
    campaign_id: 7197,
    campaign_name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
    total_current_period:
      {
        ctr: 1.82,
        uniq_count: 6003222,
        ref_count: 22949,
        unique_clicks: 22070,
        rf: 1.23,
        show_count: 1219219,
      },
    total_all_period:
      {
        ctr: 1.93,
        uniq_count: 7033222,
        ref_count: 622949,
        unique_clicks: 622070,
        rf: 1.80,
        show_count: 5319219,
      },
    statistics: [
      {
        date_string: '01-11-2022',
        ctr: 2.31,
        uniq_count: 939661,
        ref_count: 2886,
        unique_clicks: 2784,
        rf: 1.23,
        show_count: 124441,
      },
      {
        date_string: '02-11-2022',
        ctr: 1.80,
        uniq_count: 899354,
        ref_count: 2248,
        unique_clicks: 2162,
        rf: 1.23,
        show_count: 124320,
      },
      {
        date_string: '03-11-2022',
        ctr: 1.07,
        uniq_count: 236519,
        ref_count: 1330,
        unique_clicks: 1287,
        rf: 1.23,
        show_count: 123967,
      },
      {
        date_string: '04-11-2022',
        ctr: 2.88,
        uniq_count: 987939,
        ref_count: 3602,
        unique_clicks: 3443,
        rf: 1.23,
        show_count: 124809,
      },
    ],
  },
}

mock.onGet('/creatives/list/table-data').reply(request => {
  // eslint-disable-next-line camelcase
  const { campaign_id } = request.params
  // eslint-disable-next-line camelcase
  const data = dataCreatives.find(c => c.campaign_id === campaign_id)

  return [200, data]
})

mock.onGet('/creatives/list/chart-data').reply(() => [200, dataCreativesChart])
mock.onGet('/creatives/list/excel-data').reply(() => [200, dataExcelCreatives])
