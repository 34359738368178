import mock from '@/@fake-db/mock'

// поле uniq_count_period нигде не используется. Во всем проекте. Его убрал.

const dataAdvertisers = [
  {
    ctr: 0.13,
    campaigns_count: {
      inactive: 23,
      paused: 2,
      active: 2,
    },
    active_type: '1',
    uniq_count: 499107,
    description: 'тест',
    ref_count: 2157,
    advertiser_id: 1,
    advertiser_name: 'Lineage',
    show_count: 1468294,
    provider_payments: 8037.333333333333, // под вопросом, так как это поле скрыто пермоментно, но это был бюджет\стоимость
  },
  {
    ctr: 0.38,
    campaigns_count: {
      inactive: 72,
      paused: 2,
      active: 2,
    },
    active_type: '1',
    uniq_count: 207118,
    description: 'тест',
    ref_count: 1458,
    advertiser_id: 2,
    advertiser_name: 'ВТБ',
    show_count: 363960,
    provider_payments: 0,
  },
  {
    ctr: 0.68,
    campaigns_count: {
      inactive: 57,
      paused: 3,
      active: 15,
    },
    active_type: '1',
    uniq_count: 14159106,
    description: 'тест',
    ref_count: 168443,
    advertiser_id: 3,
    advertiser_name: 'Haval',
    show_count: 24718844,
    provider_payments: 0,
  },
  {
    ctr: 0.14,
    campaigns_count: {
      inactive: 114,
      paused: 9,
      active: 2,
    },
    active_type: '1',
    uniq_count: 2054209,
    description: 'тест',
    ref_count: 8351,
    advertiser_id: 405,
    advertiser_name: 'Geely',
    show_count: 5941646,
    provider_payments: 7179.290171679682,
  },
  {
    ctr: 0.14,
    campaigns_count: {
      inactive: 74,
      paused: 2,
      active: 6,
    },
    active_type: '1',
    uniq_count: 680504,
    description: '',
    ref_count: 2753,
    advertiser_id: 411,
    advertiser_name: 'Suzuki',
    show_count: 1849929,
    provider_payments: 1284.2990995286127,
  },
  {
    ctr: 0.16,
    campaigns_count: {
      inactive: 113,
      paused: 7,
      active: 48,
    },
    active_type: '1',
    uniq_count: 22742925,
    description: 'тест',
    ref_count: 84441,
    advertiser_id: 416,
    advertiser_name: 'Haval',
    show_count: 51252215,
    provider_payments: 9400.508262054798,
  },
]

const dataTotalWidget = {
  video: {
    show_count: 1546,
    ref_count: 511,
    ctr: 0.15,
  },
  desktop: {
    show_count: 3324,
    ref_count: 1254,
    ctr: 0.12,
  },
  mobile: {
    show_count: 2344,
    ref_count: 648,
    ctr: 0.9,
  },
}

// Гео - города. Заменил названия полей. Убрал show_count и show_percent, так как они не спользуются. По названию city_+locale буду выводить их на нужно языке.
const dataGeoCityWidget = [
  {
    city_en: 'Moscow',
    city_ru: 'Москва',
    ref_count: 15893,
    ref_percent: 14.69,
    city_id: 524901,
  },
  {
    city_en: 'Saint Petersburg',
    city_ru: 'Санкт-Петербург',
    ref_count: 7417,
    ref_percent: 6.86,
    city_id: 498817,
  },
  {
    city_en: 'Yekaterinburg',
    city_ru: 'Екатеринбург',
    ref_count: 5901,
    ref_percent: 5.45,
    city_id: 1486209,
  },
  {
    city_en: 'Samara',
    city_ru: 'Самара',
    ref_count: 4080,
    ref_percent: 3.77,
    city_id: 499099,
  },
  {
    city_en: 'Novosibirsk',
    city_ru: 'Новосибирск',
    ref_count: 2531,
    ref_percent: 2.34,
    city_id: 1496747,
  },
  {
    city_en: 'Rostov-on-Don',
    city_ru: 'Ростов-на-Дону',
    ref_count: 2342,
    ref_percent: 2.16,
    city_id: 501175,
  },
  {
    city_en: 'Kaliningrad',
    city_ru: 'Калининград',
    show_percent: 1.83,
    show_count: 387089,
    ref_count: 1767,
    ref_percent: 1.63,
    city_id: 554234,
  },
  {
    city_en: 'Nizhniy Novgorod',
    city_ru: 'Нижний Новгород',
    ref_count: 1626,
    ref_percent: 1.5,
    city_id: 520555,
  },
  {
    city_en: 'Ufa',
    city_ru: 'Уфа',
    ref_count: 1578,
    ref_percent: 1.46,
    city_id: 479561,
  },
  {
    city_en: 'Krasnodar',
    city_ru: 'Краснодар',
    ref_count: 1451,
    ref_percent: 1.34,
    city_id: 542420,
  },
]

// Гео - страны. Заменил названия полей. Убрал show_count и show_percent, так как они не спользуются. По названию country_+locale буду выводить их на нужно языке.
const dataGeoCountryWidget = [
  {
    country_iso_code: 'RU',
    country_ru: 'Россия',
    country_en: 'Russia',
    ref_count: 81084,
    ref_percent: 98.79,
  },
  {
    country_iso_code: 'UA',
    country_ru: 'Украина',
    country_en: 'Ukraine',
    ref_count: 204,
    ref_percent: 0.25,
  },
  {
    country_iso_code: 'KZ',
    country_ru: 'Казахстан',
    country_en: 'Kazakhstan',
    ref_count: 15,
    ref_percent: 0.02,
  },
  {
    country_iso_code: 'US',
    country_ru: 'США',
    country_en: 'USA',
    ref_count: 7,
    ref_percent: 0.01,
  },
  {
    country_iso_code: 'ES',
    country_ru: 'Испания',
    country_en: 'Spain',
    ref_count: 4,
    ref_percent: 0,
  },
  {
    country_iso_code: 'RO',
    country_ru: 'Румыния',
    country_en: 'Romania',
    ref_count: 3,
    ref_percent: 0,
  },
  {
    country_iso_code: 'BY',
    country_ru: 'Беларусь',
    country_en: 'Belarus',
    ref_count: 3,
    ref_percent: 0,
  },
  {
    country_iso_code: 'NL',
    country_ru: 'Нидерланды',
    country_en: 'Netherlands',
    ref_count: 3,
    ref_percent: 0,
  },
  {
    country_iso_code: 'AM',
    country_ru: 'Армения',
    country_en: 'Armenia',
    ref_count: 2,
    ref_percent: 0,
  },
  {
    country_iso_code: 'CH',
    country_ru: 'Швейцария',
    country_en: 'Switzerland',
    ref_count: 2,
    ref_percent: 0,
  },
]

// Тут объект сразу для графиков, что бы не преобразлвывать их на нашей стороне.

// В данном случае categories это возраст, а data это их процентное значение сответсвенно их порядку
// 0-17 - 5% || 18-24 - 20% || 25-40 - 25% || 41-90 - 25%
const dataSocDemAgeWidget = {
  categories: ['0-17', '18-24', '25-40', '41-90'], // Объект для графика возрастов.
  series: [{
    data: [5, 20, 25, 25], // Количество цифр соответвует количеству категорий и их порядку.
  }],
}

// В данном случае labels это пол, а series это их процентное значение сответсвенно их порядку
// Female - 25% || Male - 75%
const dataSocDemSexWidget = {
  labels: ['Female', 'Male'], // Это пол. Его скорее всего нужно будет перевести. Поэтому тут скорее всего будут ключи из i18n
  series: [25, 75], // Процент полов в сотвествии с их порядком.
}

// Соцдем - Категории. Названия буду выводить в зависимости от локализации category_+locace. Не спользуему поля убрал.
const dataCategoriesWidget = [
  {
    category_ru: 'Планирующие беременность',
    category_en: 'Planning pregnancy',
    percent: 65.67,
  },
  {

    category_ru: 'Витаминные комплексы',
    category_en: 'Vitamin complexes',
    percent: 35.67,
  },
  {
    category_ru: 'СМИ',
    category_en: 'mass media',
    percent: 19.78,
  },
  {
    category_ru: 'Видео',
    category_en: 'Video',
    percent: 13.31,
  },
  {
    category_ru: 'Социальная сеть',
    category_en: 'Social network',
    percent: 8.87,
  },
  {
    category_ru: 'Новости и политика',
    category_en: 'News and Politics',
    percent: 7.9,
  },
  {
    category_ru: 'Автомобили',
    category_en: 'Automotive',
    percent: 7.48,
  },
  {
    category_ru: 'Развлечения',
    category_en: 'Entertainment',
    percent: 6.64,
  },
  {
    category_ru: 'Кино',
    category_en: 'Movie',
    percent: 6.5,
  },
  {
    category_ru: 'Смартфоны',
    category_en: 'Smartphones',
    percent: 5.89,
  },
]

// Система - браузеры. Убрал browser_id, он не используется.

const dataSystemBrowsersWidget = [
  {
    browser_name: 'chrome',
    ref_count: 15219,
    ref_percent: 99.72,
  },
  {
    browser_name: 'mozilla',
    ref_count: 21,
    ref_percent: 0.14,
  },
  {
    browser_name: 'safari',
    ref_count: 20,
    ref_percent: 0.13,
  },
  {
    browser_name: 'opera',
    ref_count: 1,
    ref_percent: 0.01,
  },
]

// Система - ОС. Убрал plat_id, он не используется.
const dataSystemOSWidget = [
  {
    os_name: 'android',
    ref_count: 13400,
    ref_percent: 87.74,
  },
  {
    os_name: 'windows',
    ref_count: 1721,
    ref_percent: 11.27,
  },
  {
    os_name: 'linux',
    ref_count: 110,
    ref_percent: 0.72,
  },
  {
    os_name: 'mac',
    ref_count: 41,
    ref_percent: 0.27,
  },
]

mock.onGet('/advertisers/table_data').reply(() => [200, dataAdvertisers])

mock.onGet('/advertisers/widgets/total').reply(() => [200, dataTotalWidget])

mock.onGet('/advertisers/widgets/geo/city').reply(() => [200, dataGeoCityWidget])
mock.onGet('/advertisers/widgets/geo/country').reply(() => [200, dataGeoCountryWidget])

mock.onGet('/advertisers/widgets/soc_dem/age').reply(() => [200, dataSocDemAgeWidget])
mock.onGet('/advertisers/widgets/soc_dem/sex').reply(() => [200, dataSocDemSexWidget])
mock.onGet('/advertisers/widgets/soc_dem/categories').reply(() => [200, dataCategoriesWidget])

mock.onGet('/advertisers/widgets/system/browsers').reply(() => [200, dataSystemBrowsersWidget])
mock.onGet('/advertisers/widgets/system/os').reply(() => [200, dataSystemOSWidget])
