import { canNavigate } from '@/plugins/acl/routeProtection'
import { hasAccess } from '@/plugins/rules/routeProtection'
import jwt from 'jsonwebtoken'
import Vue from 'vue'
import VueRouter from 'vue-router'
import main from './main'

// import dashboard from './dashboard'
// import userInterface from './user-interface'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData) {
        if (Vue.prototype.$checkRole(['ROOT', 'SUPER_ADMINS', 'MANAGER_ADMINS', 'MANAGERS'])) {
          return { name: 'advertisers' }
        }
        if (Vue.prototype.$checkRole(['ADVERTISERS_USERS', 'ADVERTISERS', 'PROVIDERS', 'ADMINS'])) {
          return { name: 'campaigns-list' }
        }
      }

      return { name: 'auth-login', query: to.query }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'blank',
    },
  },

  ...main,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const userData = localStorage.getItem('userData')

  if (localStorage.getItem('accessToken')) {
    const token = jwt.decode(localStorage.getItem('accessToken'), { complete: true })

    // console.log(token)
    if (token.payload.exp < Math.floor(Date.now() / 1000)) {
      localStorage.removeItem('accessToken')
    }
  }

  const isLoggedIn = !!(userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility'))

  // Redirect to login if not logged in
  if (!isLoggedIn && to.name !== 'auth-login') return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

  if (!canNavigate(to)) {
    // If not authorized
    // localStorage.removeItem('accessToken')

    // // Remove userData & Ability from localStorage
    // localStorage.removeItem('userData')
    // localStorage.removeItem('userAbility')
    // localStorage.removeItem('userRules')

    return next({ name: 'misc-not-authorized' })

    // return next({ name: 'misc-not-authorized' })
  }

  // console.log(!hasAccess(to))
  if (!hasAccess(to)) {
    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
