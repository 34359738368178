import mock from '@/@fake-db/mock'

const dataCreativeVtr = [
  {
    events: {
      volume_off: 144993,
      volume_on: 101805,
      pause_click: 104433,
      pass_ad: 1370289,
      user_closed: 1861156,
    },
    views: {
      view_51_75: 6515463,
      view_51_75_percent: 65.34,
      view_26_50: 809613,
      view_26_50_percent: 32.32,
      view_to_10: 33457,
      view_to_10_percent: 10.35,
      view_76_99: 453443,
      view_76_99_percent: 5.36,
      view_11_25: 45334535,
      view_11_25_percent: 4.65,
      view_to_50_75: 9467456,
      view_to_50_75_percent: 65.42,
      view_to_25_50: 10120238,
      view_to_25_50_percent: 10.36,
      view_to_0_25: 11671060,
      view_to_0_25_percent: 6.84,
      view_100: 4534453,
      view_100_percent: 2.34,
      view_to_75_100: 8729205,
      view_to_75_100_percent: 8.64,
    },
    vtr_type: 1,
    creative_id: 50720,
    sum_all_views_all_peroid: 6516433214,
    campaign_id: 7197,
  },
  {
    events: {
      volume_off: 144993,
      volume_on: 101805,
      pause_click: 104433,
      pass_ad: 1370289,
      user_closed: 1861156,
    },
    views: {
      view_51_75: 6515463,
      view_51_75_percent: 65.34,
      view_26_50: 809613,
      view_26_50_percent: 32.32,
      view_to_10: 33457,
      view_to_10_percent: 10.35,
      view_76_99: 453443,
      view_76_99_percent: 5.36,
      view_11_25: 45334535,
      view_11_25_percent: 4.65,
      view_to_50_75: 9467456,
      view_to_50_75_percent: 65.42,
      view_to_25_50: 10120238,
      view_to_25_50_percent: 10.36,
      view_to_0_25: 11671060,
      view_to_0_25_percent: 6.84,
      view_100: 4534453,
      view_100_percent: 2.34,
      view_to_75_100: 8729205,
      view_to_75_100_percent: 8.64,
    },
    vtr_type: 2,
    creative_id: 50721,
    sum_all_views_all_peroid: 321113211,
    campaign_id: 7197,
  },
  {
    events: {
      volume_off: 144993,
      volume_on: 101805,
      pause_click: 104433,
      pass_ad: 1370289,
      user_closed: 1861156,
    },
    views: {
      view_51_75: 6515463,
      view_51_75_percent: 65.34,
      view_26_50: 809613,
      view_26_50_percent: 32.32,
      view_to_10: 33457,
      view_to_10_percent: 10.35,
      view_76_99: 453443,
      view_76_99_percent: 5.36,
      view_11_25: 45334535,
      view_11_25_percent: 4.65,
      view_to_50_75: 9467456,
      view_to_50_75_percent: 65.42,
      view_to_25_50: 10120238,
      view_to_25_50_percent: 10.36,
      view_to_0_25: 11671060,
      view_to_0_25_percent: 6.84,
      view_100: 4534453,
      view_100_percent: 2.34,
      view_to_75_100: 8729205,
      view_to_75_100_percent: 8.64,
    },
    vtr_type: 1,
    creative_id: 50722,
    sum_all_views_all_peroid: 321113211,
    campaign_id: 7197,
  },
  {
    events: {
      volume_off: 144993,
      volume_on: 101805,
      pause_click: 104433,
      pass_ad: 1370289,
      user_closed: 1861156,
    },
    views: {
      view_51_75: 6515463,
      view_51_75_percent: 65.34,
      view_26_50: 809613,
      view_26_50_percent: 32.32,
      view_to_10: 33457,
      view_to_10_percent: 10.35,
      view_76_99: 453443,
      view_76_99_percent: 5.36,
      view_11_25: 45334535,
      view_11_25_percent: 4.65,
      view_to_50_75: 9467456,
      view_to_50_75_percent: 65.42,
      view_to_25_50: 10120238,
      view_to_25_50_percent: 10.36,
      view_to_0_25: 11671060,
      view_to_0_25_percent: 6.84,
      view_100: 4534453,
      view_100_percent: 2.34,
      view_to_75_100: 8729205,
      view_to_75_100_percent: 8.64,
    },
    vtr_type: 1,
    creative_id: 50723,
    sum_all_views_all_peroid: 321113211,
    campaign_id: 7197,
  },
  {
    events: {
      volume_off: 144993,
      volume_on: 101805,
      pause_click: 104433,
      pass_ad: 1370289,
      user_closed: 1861156,
    },
    views: {
      view_51_75: 6515463,
      view_51_75_percent: 65.34,
      view_26_50: 809613,
      view_26_50_percent: 32.32,
      view_to_10: 33457,
      view_to_10_percent: 10.35,
      view_76_99: 453443,
      view_76_99_percent: 5.36,
      view_11_25: 45334535,
      view_11_25_percent: 4.65,
      view_to_50_75: 9467456,
      view_to_50_75_percent: 65.42,
      view_to_25_50: 10120238,
      view_to_25_50_percent: 10.36,
      view_to_0_25: 11671060,
      view_to_0_25_percent: 6.84,
      view_100: 4534453,
      view_100_percent: 2.34,
      view_to_75_100: 8729205,
      view_to_75_100_percent: 8.64,
    },
    vtr_type: 2,
    creative_id: 50730,
    sum_all_views_all_peroid: 321113211,
    campaign_id: 7228,
  },
  {
    events: {
      volume_off: 144993,
      volume_on: 101805,
      pause_click: 104433,
      pass_ad: 1370289,
      user_closed: 1861156,
    },
    views: {
      view_51_75: 6515463,
      view_51_75_percent: 65.34,
      view_26_50: 809613,
      view_26_50_percent: 32.32,
      view_to_10: 33457,
      view_to_10_percent: 10.35,
      view_76_99: 453443,
      view_76_99_percent: 5.36,
      view_11_25: 45334535,
      view_11_25_percent: 4.65,
      view_to_50_75: 9467456,
      view_to_50_75_percent: 65.42,
      view_to_25_50: 10120238,
      view_to_25_50_percent: 10.36,
      view_to_0_25: 11671060,
      view_to_0_25_percent: 6.84,
      view_100: 4534453,
      view_100_percent: 2.34,
      view_to_75_100: 8729205,
      view_to_75_100_percent: 8.64,
    },
    vtr_type: 2,
    creative_id: 50731,
    sum_all_views_all_peroid: 321113211,
    campaign_id: 7228,
  },
]

const dataVTRExcel = [
  {
    campaign_id: 7197,
    campaign_name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
    creative_id: 50720,
    vtr_type: 1,
    vtr_statistics: {
      events: {
        volume_off: 144993,
        volume_on: 101805,
        pause_click: 104433,
        pass_ad: 1370289,
        user_closed: 1861156,
      },
      views: {
        view_51_75: 6515463,
        view_51_75_percent: 65.34,
        view_26_50: 809613,
        view_26_50_percent: 32.32,
        view_to_10: 33457,
        view_to_10_percent: 10.35,
        view_76_99: 453443,
        view_76_99_percent: 5.36,
        view_11_25: 45334535,
        view_11_25_percent: 4.65,
        view_to_50_75: 9467456,
        view_to_50_75_percent: 65.42,
        view_to_25_50: 10120238,
        view_to_25_50_percent: 10.36,
        view_to_0_25: 11671060,
        view_to_0_25_percent: 6.84,
        view_100: 4534453,
        view_100_percent: 2.34,
        view_to_75_100: 8729205,
        view_to_75_100_percent: 8.64,
      },
    },
    daily_statistics: {
      total_all_period: {
        ctr: 1.93,
        uniq_count: 1033222,
        ref_count: 622949,
        unique_clicks: 622070,
        show_count: 5319219,
      },
      statistics: [
        {
          date_string: '01-11-2022',
          ctr: 2.31,
          uniq_count: 99661,
          ref_count: 3886,
          unique_clicks: 2784,
          rf: 1.23,
          show_count: 124441,
        },
        {
          date_string: '02-11-2022',
          ctr: 2.31,
          uniq_count: 99661,
          ref_count: 2886,
          unique_clicks: 2784,
          rf: 1.23,
          show_count: 124441,
        },
        {
          date_string: '03-11-2022',
          ctr: 2.31,
          uniq_count: 99661,
          ref_count: 4886,
          unique_clicks: 2784,
          rf: 1.23,
          show_count: 124441,
        },
        {
          date_string: '04-11-2022',
          ctr: 2.31,
          uniq_count: 99661,
          ref_count: 5886,
          unique_clicks: 2784,
          rf: 1.23,
          show_count: 124441,
        },
      ],
    },
  },
  {
    campaign_id: 7197,
    campaign_name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
    creative_id: 50721,
    vtr_type: 1,
    vtr_statistics: {
      events: {
        volume_off: 144993,
        volume_on: 101805,
        pause_click: 104433,
        pass_ad: 1370289,
        user_closed: 1861156,
      },
      views: {
        view_51_75: 6515463,
        view_51_75_percent: 65.34,
        view_26_50: 809613,
        view_26_50_percent: 32.32,
        view_to_10: 33457,
        view_to_10_percent: 10.35,
        view_76_99: 453443,
        view_76_99_percent: 5.36,
        view_11_25: 45334535,
        view_11_25_percent: 4.65,
        view_to_50_75: 9467456,
        view_to_50_75_percent: 65.42,
        view_to_25_50: 10120238,
        view_to_25_50_percent: 10.36,
        view_to_0_25: 11671060,
        view_to_0_25_percent: 6.84,
        view_100: 4534453,
        view_100_percent: 2.34,
        view_to_75_100: 8729205,
        view_to_75_100_percent: 8.64,
      },
    },
    daily_statistics: {
      total_all_period: {
        ctr: 1.93,
        uniq_count: 1033222,
        ref_count: 622949,
        unique_clicks: 622070,
        show_count: 5319219,
      },
      statistics: [
        {
          date_string: '01-11-2022',
          ctr: 2.31,
          uniq_count: 99661,
          ref_count: 2886,
          unique_clicks: 2784,
          rf: 1.23,
          show_count: 124441,
        },
        {
          date_string: '02-11-2022',
          ctr: 2.31,
          uniq_count: 99661,
          ref_count: 2886,
          unique_clicks: 2784,
          rf: 1.23,
          show_count: 124441,
        },
        {
          date_string: '03-11-2022',
          ctr: 2.31,
          uniq_count: 99661,
          ref_count: 2886,
          unique_clicks: 2784,
          rf: 1.23,
          show_count: 124441,
        },
        {
          date_string: '04-11-2022',
          ctr: 2.31,
          uniq_count: 99661,
          ref_count: 2886,
          unique_clicks: 2784,
          rf: 1.23,
          show_count: 124441,
        },
      ],
    },
  },
  {
    campaign_id: 7197,
    campaign_name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
    creative_id: 50722,
    vtr_type: 1,
    vtr_statistics: {
      events: {
        volume_off: 144993,
        volume_on: 101805,
        pause_click: 104433,
        pass_ad: 1370289,
        user_closed: 1861156,
      },
      views: {
        view_51_75: 6515463,
        view_51_75_percent: 65.34,
        view_26_50: 809613,
        view_26_50_percent: 32.32,
        view_to_10: 33457,
        view_to_10_percent: 10.35,
        view_76_99: 453443,
        view_76_99_percent: 5.36,
        view_11_25: 45334535,
        view_11_25_percent: 4.65,
        view_to_50_75: 9467456,
        view_to_50_75_percent: 65.42,
        view_to_25_50: 10120238,
        view_to_25_50_percent: 10.36,
        view_to_0_25: 11671060,
        view_to_0_25_percent: 6.84,
        view_100: 4534453,
        view_100_percent: 2.34,
        view_to_75_100: 8729205,
        view_to_75_100_percent: 8.64,
      },
    },
    daily_statistics: {
      total_all_period: {
        ctr: 1.93,
        uniq_count: 1033222,
        ref_count: 622949,
        unique_clicks: 622070,
        show_count: 5319219,
      },
      statistics: [
        {
          date_string: '01-11-2022',
          ctr: 2.31,
          uniq_count: 99661,
          ref_count: 2886,
          unique_clicks: 2784,
          rf: 1.23,
          show_count: 124441,
        },
        {
          date_string: '02-11-2022',
          ctr: 2.31,
          uniq_count: 99661,
          ref_count: 2886,
          unique_clicks: 2784,
          rf: 1.23,
          show_count: 124441,
        },
        {
          date_string: '03-11-2022',
          ctr: 2.31,
          uniq_count: 99661,
          ref_count: 2886,
          unique_clicks: 2784,
          rf: 1.23,
          show_count: 124441,
        },
        {
          date_string: '04-11-2022',
          ctr: 2.31,
          uniq_count: 99661,
          ref_count: 2886,
          unique_clicks: 2784,
          rf: 1.23,
          show_count: 124441,
        },
      ],
    },
  },
]

mock.onGet('/creatives/vtr/data').reply(request => {
  // eslint-disable-next-line camelcase
  const { creative_id } = request.params
  // eslint-disable-next-line camelcase
  const data = dataCreativeVtr.find(c => c.creative_id === creative_id)

  return [200, data]
})

mock.onGet('/creatives/vtr/excel-data').reply(request => {
  // eslint-disable-next-line camelcase
  const { creative_id } = request.params
  // eslint-disable-next-line camelcase
  const data = dataVTRExcel.find(c => c.creative_id === creative_id)

  return [200, data]
})

mock.onPost('/creatives/vtr/request').reply(() => [200, { ok: 'ok' }])
