<template>
  <v-menu
    :key="userData.name || userData.avatar"
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="d-inline-flex flex-column justify-center ms-3"
        style="vertical-align:middle"
        v-bind="attrs"
        v-on="on"
      >
        <v-row class="mr-4 ml-3">
          <v-col
            cols="9"
            md="9"
            sm="9"
          >
            <span
              class="text--primary font-weight-semibold mb-n1"
            >
              {{ userData.name || userData.username }},
            </span>
            <br>
            <small class="text--disabled text-capitalize">{{ $t('Welcome') }}</small>
          </v-col>
          <v-col
            cols="3"
            md="3"
            sm="3"
            class="d-flex justify-center"
          >
            <v-avatar
              size="50px"
              color="primary"
              class="v-avatar-light-bg primary--text"
            >
              <v-img
                v-if="userData.avatarBase64"
                :src="userData.avatarBase64"
              ></v-img>
              <v-icon
                v-else
                color="primary"
                size="28"
              >
                {{ icons.mdiAccountOutline }}
              </v-icon>
            </v-avatar>
          </v-col>
        </v-row>
      </div>
    </template>
    <v-list>
      <!-- Profile -->
      <v-list-item :to="{ name: 'profile-edit'}">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('Profile') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('Logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import { initialAbility } from '@/plugins/acl/config'
import { getCurrentInstance } from '@vue/composition-api'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userAvatar = localStorage.getItem('userAvatar')

    const logoutUser = () => {
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken')

      // Remove userData & Ability from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')
      localStorage.removeItem('userRules')

      // Reset ability
      vm.$ability.update(initialAbility)

      // Redirect to login page
      router.push({ name: 'auth-login' })
    }

    return {
      logoutUser,
      userData,
      userAvatar,

      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
  mounted() {
    this.$eventHub.$on('updateUserData', () => {
      this.userData = JSON.parse(localStorage.getItem('userData'))
      this.$forceUpdate()
    })
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
