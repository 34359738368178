import mock from '@/@fake-db/mock'

const reportList = [
  {
    id: 1,
    name: '321_awd2332a1wd_10012154',
    createdDate: '2023-01-18 02:15:18',
    updatedDate: '2023-01-18 03:15:18',
    status: 'NEW',
    user: 'root',
    parameters: {
      date_to: 1674075599000,
      date_from: 1672520400000,
      campaignIds: [186],
      advertiserId: 245,
    },
    path: '/data/spool/md-mediadesk-app/reports/231_2023-01-18_110108235.xlsx',
    userId: 231,
  },
  {
    id: 2,
    name: '321_awd2332a1wd_10012',
    createdDate: '2023-01-18 02:15:18',
    updatedDate: '2023-01-18 03:15:18',
    status: 'COMPLETED',
    user: 'root',
    parameters: {
      date_to: 1674075599000,
      date_from: 1672520400000,
      campaignIds: [186],
      advertiserId: 245,
    },
    path: '/data/spool/md-mediadesk-app/reports/231_2023-01-18_110108235.xlsx',
    userId: 231,
  },
  {
    id: 3,
    name: '321_awd2332a1wd_10012',
    createdDate: '2023-01-18 02:15:18',
    updatedDate: '2023-01-18 03:15:18',
    status: 'IN_PROGRESS',
    user: 'root',
    parameters: {
      date_to: 1674075599000,
      date_from: 1672520400000,
      campaignIds: [186, 321, 321],
      advertiserId: 245,
    },
    path: '/data/spool/md-mediadesk-app/reports/231_2023-01-18_110108235.xlsx',
    userId: 231,
  },
  {
    id: 4,
    name: '321_awd2332a1wd_1001awd6651a6w5d12',
    createdDate: '2023-01-18 02:15:18',
    updatedDate: '2023-01-18 03:15:18',
    status: 'FAILED',
    user: 'root',
    parameters: {
      date_to: 1674075599000,
      date_from: 1672520400000,
      campaignIds: [186, 321, 321],
      advertiserId: 245,
    },
    path: '/data/spool/md-mediadesk-app/reports/231_2023-01-18_110108235.xlsx',
    userId: 231,
  },
]

mock.onGet('generate-report/report-list').reply(() => [200, reportList])
