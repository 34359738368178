import mock from '@/@fake-db/mock'

// поле uniq_count_period нигде не используется. Во всем проекте. Его убрал.

const dataCampaigns = [
  {
    viewability: 0,
    advertiserId: 1,
    providersId: 1,
    id: 7197,
    ctr: 1.2,
    uniqViews: 314,
    finish: 1670912919,
    clicks: 615,
    shows: 3215,
    fraud: 1.2,
    name: '7197 // MDP // РЖД // 9 Мая // 30.04-20.05 // 11 080 clicks',
    views: 651,
    start: 1670912919,
    status: 'active',
    provider_payments: 0, // Под вопросом, так как это поле использовалось как бюджет\стоимость, но сейчас скрыто пермоментно
  },
  {
    viewability: 0,
    advertiserId: 1,
    providersId: 2,
    id: 7228,
    ctr: 1.2,
    uniqViews: 314,
    finish: 1670912919,
    clicks: 615,
    shows: 3215,
    fraud: 1.2,
    name: '7228 // MDP // РЖД // 9 Мая // Раздел сайта // 30.04-20.05 // 4 774 clicks',
    views: 651,
    start: 1670912919,
    status: 'active',
    provider_payments: 0,
  },
  {
    viewability: 0,
    advertiserId: 1,
    providersId: 2,
    id: 2734,
    ctr: 1.2,
    uniqViews: 314,
    finish: 1670912919,
    clicks: 615,
    shows: 3215,
    fraud: 1.2,
    name: '2734 // Pharmamed // Витамишки // январь-март18 // video // 7 132 120 imp',
    views: 651,
    start: 1670912919,
    status: 'suspended',
    provider_payments: 0,
  },
  {
    viewability: 0,
    advertiserId: 1,
    providersId: 3,
    id: 2795,
    ctr: 1.2,
    uniqViews: 314,
    finish: 1670912919,
    clicks: 615,
    shows: 3215,
    fraud: 1.2,
    name: 'Ladys Formula Менопауза // YouTube TrueView // 897 866 просмотров',
    views: 651,
    start: 1670912919,
    status: 'finished',
    provider_payments: 0,
  },
  {
    viewability: 0,
    advertiserId: 153,
    providersId: 3,
    id: 477,
    ctr: 1.2,
    uniqViews: 314,
    finish: 1670912919,
    clicks: 615,
    shows: 3215,
    fraud: 1.2,
    name: 'Росбанк // Сверхкарта+ 2016 // кредитная карта // audience // 1228 clicks',
    views: 651,
    start: 1670912919,
    status: 'active',
    provider_payments: 0,
  },
  {
    viewability: 0,
    advertiserId: 156,
    providersId: 2,
    id: 479,
    ctr: 1.2,
    uniqViews: 314,
    finish: 1670912919,
    clicks: 615,
    shows: 3215,
    fraud: 1.2,
    name: ' Росбанк // Сверхкарта+ 2016 // july // финансы // audience // 1228 clicks',
    views: 651,
    start: 1670912919,
    status: 'active',
    provider_payments: 0,
  },
]

const dataActivityWidget = {
  series: [
    {
      name: 'Shows',
      data: [1234, 1334, 1343, 4345, 3453, 4534, 3453, 4533, 5634, 3456, 7842, 2543, 1533],
    },
    {
      name: 'References',
      data: [60, 80, 70, 110, 80, 100, 90, 180, 160, 140, 200, 220, 275],
    },
    {
      name: 'Сoverage',
      data: [1021, 1230, 1124, 3365, 2354, 3654, 2314, 3254, 4214, 2365, 6541, 1254, 642],
    },
  ],
  categories: [
    '15-10',
    '16-10',
    '17-10',
    '18-10',
    '19-10',
    '20-10',
    '21-10',
    '22-10',
    '23-10',
    '24-10',
    '25-10',
    '26-10',
    '27-10',
  ],
}

const dataTotalWidget = {
  video: {
    views: 1546,
    clicks: 511,
    ctr: 0.15,
  },
  desktop: {
    views: 3324,
    clicks: 1254,
    ctr: 0.12,
  },
  mobile: {
    views: 2344,
    clicks: 648,
    ctr: 0.9,
  },
}

// Гео - города. Заменил названия полей. Убрал show_count и show_percent, так как они не спользуются. По названию city_+locale буду выводить их на нужно языке.
const dataGeoCityWidget = [
  {
    cityNameEn: 'Moscow',
    cityNameRu: 'Москва',
    clicks: 15893,
    clicksPercent: 14.69,
  },
  {
    cityNameEn: 'Saint Petersburg',
    cityNameRu: 'Санкт-Петербург',
    clicks: 7417,
    clicksPercent: 6.86,
  },
  {
    cityNameEn: 'Yekaterinburg',
    cityNameRu: 'Екатеринбург',
    clicks: 5901,
    clicksPercent: 5.45,
  },
  {
    cityNameEn: 'Samara',
    cityNameRu: 'Самара',
    clicks: 4080,
    clicksPercent: 3.77,
  },
  {
    cityNameEn: 'Novosibirsk',
    cityNameRu: 'Новосибирск',
    clicks: 2531,
    clicksPercent: 2.34,
  },
  {
    cityNameEn: 'Rostov-on-Don',
    cityNameRu: 'Ростов-на-Дону',
    clicks: 2342,
    clicksPercent: 2.16,
  },
  {
    cityNameEn: 'Kaliningrad',
    cityNameRu: 'Калининград',
    clicks: 1767,
    clicksPercent: 1.63,
  },
  {
    cityNameEn: 'Nizhniy Novgorod',
    cityNameRu: 'Нижний Новгород',
    clicks: 1626,
    clicksPercent: 1.5,
  },
  {
    cityNameEn: 'Ufa',
    cityNameRu: 'Уфа',
    clicks: 1578,
    clicksPercent: 1.46,
  },
  {
    cityNameEn: 'Krasnodar',
    cityNameRu: 'Краснодар',
    clicks: 1451,
    clicksPercent: 1.34,
  },
]

// Гео - страны. Заменил названия полей. Убрал show_count и show_percent, так как они не спользуются. По названию country_+locale буду выводить их на нужно языке.
const dataGeoCountryWidget = [
  {
    country_iso_code: 'RU',
    countryNameRu: 'Россия',
    countryNameEn: 'Russia',
    clicks: 81084,
    clicksPercent: 98.79,
  },
  {
    country_iso_code: 'UA',
    countryNameRu: 'Украина',
    countryNameEn: 'Ukraine',
    clicks: 204,
    clicksPercent: 0.25,
  },
  {
    country_iso_code: 'KZ',
    countryNameRu: 'Казахстан',
    countryNameEn: 'Kazakhstan',
    clicks: 15,
    clicksPercent: 0.02,
  },
  {
    country_iso_code: 'US',
    countryNameRu: 'США',
    countryNameEn: 'USA',
    clicks: 7,
    clicksPercent: 0.01,
  },
  {
    country_iso_code: 'ES',
    countryNameRu: 'Испания',
    countryNameEn: 'Spain',
    clicks: 4,
    clicksPercent: 0,
  },
  {
    country_iso_code: 'RO',
    countryNameRu: 'Румыния',
    countryNameEn: 'Romania',
    clicks: 3,
    clicksPercent: 0,
  },
  {
    country_iso_code: 'BY',
    countryNameRu: 'Беларусь',
    countryNameEn: 'Belarus',
    clicks: 3,
    clicksPercent: 0,
  },
  {
    country_iso_code: 'NL',
    countryNameRu: 'Нидерланды',
    countryNameEn: 'Netherlands',
    clicks: 3,
    clicksPercent: 0,
  },
  {
    country_iso_code: 'AM',
    countryNameRu: 'Армения',
    countryNameEn: 'Armenia',
    clicks: 2,
    clicksPercent: 0,
  },
  {
    country_iso_code: 'CH',
    countryNameRu: 'Швейцария',
    countryNameEn: 'Switzerland',
    clicks: 2,
    clicksPercent: 0,
  },
]

// Тут объект сразу для графиков, что бы не преобразлвывать их на нашей стороне.

// В данном случае categories это возраст, а data это их процентное значение сответсвенно их порядку
// 0-17 - 5% || 18-24 - 20% || 25-40 - 25% || 41-90 - 25%
const dataSocDemAgeWidget = {
  categories: ['0-17', '18-24', '25-40', '41-90'], // Объект для графика возрастов.
  series: [{
    data: [5, 20, 25, 25], // Количество цифр соответвует количеству категорий и их порядку.
  }],
}

// В данном случае labels это пол, а series это их процентное значение сответсвенно их порядку
// Female - 25% || Male - 75%
const dataSocDemSexWidget = {
  labels: ['Female', 'Male'], // Это пол. Его скорее всего нужно будет перевести. Поэтому тут скорее всего будут ключи из i18n
  series: [25, 75], // Процент полов в сотвествии с их порядком.
}

// Соцдем - Категории. Названия буду выводить в зависимости от локализации category_+locace. Не спользуему поля убрал.
const dataCategoriesWidget = [
  {
    category_ru: 'Планирующие беременность',
    category_en: 'Planning pregnancy',
    percent: 65.67,
  },
  {

    category_ru: 'Витаминные комплексы',
    category_en: 'Vitamin complexes',
    percent: 35.67,
  },
  {
    category_ru: 'СМИ',
    category_en: 'mass media',
    percent: 19.78,
  },
  {
    category_ru: 'Видео',
    category_en: 'Video',
    percent: 13.31,
  },
  {
    category_ru: 'Социальная сеть',
    category_en: 'Social network',
    percent: 8.87,
  },
  {
    category_ru: 'Новости и политика',
    category_en: 'News and Politics',
    percent: 7.9,
  },
  {
    category_ru: 'Автомобили',
    category_en: 'Automotive',
    percent: 7.48,
  },
  {
    category_ru: 'Развлечения',
    category_en: 'Entertainment',
    percent: 6.64,
  },
  {
    category_ru: 'Кино',
    category_en: 'Movie',
    percent: 6.5,
  },
  {
    category_ru: 'Смартфоны',
    category_en: 'Smartphones',
    percent: 5.89,
  },
]

// Система - браузеры. Убрал browser_id, он не используется.

const dataSystemBrowsersWidget = [
  {
    browser_name: 'chrome',
    ref_count: 15219,
    ref_percent: 99.72,
  },
  {
    browser_name: 'mozilla',
    ref_count: 21,
    ref_percent: 0.14,
  },
  {
    browser_name: 'safari',
    ref_count: 20,
    ref_percent: 0.13,
  },
  {
    browser_name: 'opera',
    ref_count: 1,
    ref_percent: 0.01,
  },
]

// Система - ОС. Убрал plat_id, он не используется.
const dataSystemOSWidget = [
  {
    os_name: 'android',
    ref_count: 13400,
    ref_percent: 87.74,
  },
  {
    os_name: 'windows',
    ref_count: 1721,
    ref_percent: 11.27,
  },
  {
    os_name: 'linux',
    ref_count: 110,
    ref_percent: 0.72,
  },
  {
    os_name: 'mac',
    ref_count: 41,
    ref_percent: 0.27,
  },
]

const dataAdvertisers = [
  {
    name: 'Lineage',
    id: 1,
  },
  {
    name: 'ВТБ',
    id: 2,
  },
  {
    name: 'Haval',
    id: 3,
  },
  {
    name: 'Geely',
    id: 4,
  },
]

const dataProviders = [
  {
    name: 'Adspend',
    id: 1,
  },
  {
    name: 'GetIntent',
    id: 2,
  },
  {
    name: 'Lisinii',
    id: 3,
  },
  {
    name: 'GHHGHG',
    id: 4,
  },
]

mock.onGet('/campaigns/table_data').reply(request => {
  // eslint-disable-next-line camelcase
  const { advertiserId, providersId } = request.params

  let campaigns = []

  if (advertiserId) {
  // eslint-disable-next-line camelcase
    campaigns = dataCampaigns.filter(campaign => campaign.advertiserId === advertiserId)
  }
  if (providersId) {
    // eslint-disable-next-line camelcase
    campaigns = dataCampaigns.filter(campaign => campaign.providersId === providersId)
  }

  return [200, campaigns]
})

mock.onGet('/campaigns/widgets/total').reply(() => [200, dataTotalWidget])

mock.onGet('/campaigns/widgets/avtivity').reply(() => [200, dataActivityWidget])

mock.onGet('/campaigns/widgets/geo/city').reply(() => [200, dataGeoCityWidget])
mock.onGet('/campaigns/widgets/geo/country').reply(() => [200, dataGeoCountryWidget])

mock.onGet('/campaigns/widgets/soc_dem/age').reply(() => [200, dataSocDemAgeWidget])
mock.onGet('/campaigns/widgets/soc_dem/sex').reply(() => [200, dataSocDemSexWidget])
mock.onGet('/campaigns/widgets/soc_dem/categories').reply(() => [200, dataCategoriesWidget])

mock.onGet('/campaigns/widgets/system/browsers').reply(() => [200, dataSystemBrowsersWidget])
mock.onGet('/campaigns/widgets/system/os').reply(() => [200, dataSystemOSWidget])
mock.onGet('/campaings/advertisers').reply(() => [200, dataAdvertisers])
mock.onGet('/campaings/providers').reply(() => [200, dataProviders])
