import mock from '@/@fake-db/mock'

const dataCategory = [
  {
    'categoryPercent': 62.11,
    'clicks': 625,
    'categoryNameEn': 'Renovation',
    'categoryId': 56,
    'categoryParentId': 16,
    'categoryNameRu': 'Ремонт',
    'campaignId': 10544,
  },
  {
    'categoryPercent': 59.73,
    'clicks': 575,
    'categoryNameEn': 'mass media',
    'categoryId': 259,
    'categoryParentId': 0,
    'categoryNameRu': 'СМИ',
    'campaignId': 10544,
  },
  {
    'categoryPercent': 39.35,
    'clicks': 321,
    'categoryNameEn': 'News and Politics',
    'categoryId': 17,
    'categoryParentId': 0,
    'categoryNameRu': 'Новости и политика',
    'campaignId': 10544,
  },
]

const dataAge = [
  {
    'clicks': 194,
    'gender': 'M',
    'percent': 18.95,
    'cam': 10466,
    'age': '41-90',
  },
  {
    'clicks': 159,
    'gender': 'W',
    'percent': 15.53,
    'cam': 10466,
    'age': '41-90',
  },
  {
    'clicks': 304,
    'gender': 'W',
    'percent': 29.69,
    'cam': 10466,
    'age': '25-40',
  },
  {
    'clicks': 367,
    'gender': 'M',
    'percent': 35.84,
    'cam': 10466,
    'age': '25-40',
  },
]

mock.onGet('/audience/category/data').reply(() => [200, dataCategory])
mock.onGet('/audience/age/data').reply(() => [200, dataAge])
