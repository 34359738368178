import mock from '@/@fake-db/mock'

const data = [
  {
    "id": 1195889,
    "domain": "theoutlet.ru",
  },
  {
    "id": 1184733,
    "domain": "theraflex.ru",
  },
  {
    "id": 1196428,
    "domain": "tomilinosamolet.ru/",
  },
]

mock.onGet('/tracking-code/data').reply(() => [200, data])

mock.onPost('/tracking-code')
  .reply(config => {
    data.push(JSON.parse(config.data))
    return [201, JSON.parse(config.data)]
  })
