import mock from '@/@fake-db/mock'
import jwt from 'jsonwebtoken'

const data = {
  users: [
    {
      id: 231,
      name: 'Root', // name
      password: '666666', // password
      // eslint-disable-next-line global-require
      avatar: require('@/assets/images/avatars/1.png'),
      email: 'root@a.com', // email
      role: 'ROOT',
      advertisers: [126, 127, 12, 56, 95, 35, 65, 67, 2], // adv_id
      dashboardAccess: true, // edit_access || true if 6
      abilities: [ // acl_pro table
        {
          action: 'manage', // manage by default
          subject: 'USERS', // rout_name field
        },
        {
          action: 'manage',
          subject: 'USER_CREATE',
        },
        {
          action: 'manage',
          subject: 'USER_UPDATE',
        },
        {
          action: 'manage',
          subject: 'USER_DELETE',
        },
        {
          action: 'manage',
          subject: 'PROVIDERS',
        },
        {
          action: 'manage',
          subject: 'ADVERTISING_CAMPAIGNS',
        },
        {
          action: 'manage',
          subject: 'ADVERTISING_CAMPAIGN_CREATE',
        },
        {
          action: 'manage',
          subject: 'ADVERTISING_CAMPAIGN_UPDATE',
        },
        {
          action: 'manage',
          subject: 'ADVERTISING_CAMPAIGN_DELETE',
        },
        {
          action: 'manage',
          subject: 'ADVERTISING_CAMPAIGNS_EXPORT',
        },
        {
          action: 'manage',
          subject: 'PROVIDER_CREATE',
        },
        {
          action: 'manage',
          subject: 'RROVIDER_UPDATE',
        },
        {
          action: 'manage',
          subject: 'PROVIDER_DELETE',
        },
        {
          action: 'manage',
          subject: 'SYS_ACCESS',
        },
        {
          action: 'manage',
          subject: 'STAT_ACCESS',
        },
        {
          action: 'manage',
          subject: 'CAN_VIEW_FRAUD_AND_VIEWABLITY',
        },
        {
          action: 'manage',
          subject: 'CAN_EDIT_FRAUD_AND_VIEWABLITY',
        },
        {
          action: 'manage',
          subject: 'REPORT_VTR',
        },
        {
          action: 'manage',
          subject: 'REPORT_CITY',
        },
        {
          action: 'manage',
          subject: 'REPORT_CAMPAIGN_STATISTICS',
        },
        {
          action: 'manage',
          subject: 'REPORT_AUDIENCE_DEMOGRAPHY',
        },
        {
          action: 'manage',
          subject: 'REPORT_CREATIVES',
        },
        {
          action: 'manage',
          subject: 'REPORTS',
        },
        {
          action: 'manage',
          subject: 'TRACKING_PIXEL',
        },
        {
          action: 'manage',
          subject: 'TRACKING_PIXEL_CREATE',
        },
        {
          action: 'manage',
          subject: 'TRACKING_PIXEL_UPDATE',
        },
        {
          action: 'manage',
          subject: 'TRACKING_PIXEL_DELETE',
        },
        {
          action: 'manage',
          subject: 'REPORT_PROVIDERS',
        },
        {
          action: 'manage',
          subject: 'LINK_CAMPAIGNS',
        },
        {
          action: 'manage',
          subject: 'REPORT_AUDIENCE_SITES',
        },
        {
          action: 'manage',
          subject: 'REPORT_AUDIENCE_CATEGORIES',
        },
        {
          action: 'manage',
          subject: 'REPORT_RATE',
        },
        {
          action: 'manage',
          subject: 'REPORT_VTR_MEDIADESK',
        },
        {
          action: 'manage',
          subject: 'LINK_USERS',
        },
        {
          action: 'manage',
          subject: 'APP_EVENT_LOGS',
        },
        {
          action: 'manage',
          subject: 'REPORT_BRANDS',
        },
        {
          action: 'manage',
          subject: 'REMAGIC',
        },
        {
          action: 'manage',
          subject: 'ACCESS_PERSONAL_CABINET',
        },
        {
          action: 'manage',
          subject: 'ACCESS_MEDIADESK_MDPROGRAMMATIC_CABINET',
        },
        {
          action: 'manage',
          subject: 'ACCESS_ADSPEND_CABINET',
        },
        {
          action: 'manage',
          subject: 'ACCESS_MEDIADESK_CABINET',
        },
      ],
    },
    {
      id: 236,
      name: 'Lisi',
      password: '666666',
      // eslint-disable-next-line global-require
      avatar: require('@/assets/images/avatars/2.png'),
      email: 'lisi.lisi.lisi@lisi.ru',
      role: 'ADVERTISERS', // group || advertiser = 2
      advertisers: null, // adv_id || empty if user role advertiser
      dashboardAccess: true, // edit_access || true if 6
      custom_properties: { type: 'properties.advertiser', objectId: '33323fb7-735e-43fb-b8cf-515973707eb2', agency: 'Лисиновое' },
      abilities: [
        {
          action: 'manage',
          subject: 'USERS',
        },
        {
          action: 'manage',
          subject: 'USER_CREATE',
        },
        {
          action: 'manage',
          subject: 'USER_UPDATE',
        },
        {
          action: 'manage',
          subject: 'USER_DELETE',
        },
        {
          action: 'manage',
          subject: 'ADVERTISING_CAMPAIGNS',
        },
        {
          action: 'manage',
          subject: 'REPORTS',
        },
        {
          action: 'manage',
          subject: 'REPORT_PROVIDERS',
        },
        {
          action: 'manage',
          subject: 'REPORT_CITY',
        },
        {
          action: 'manage',
          subject: 'REPORT_CAMPAIGN_STATISTICS',
        },
        {
          action: 'manage',
          subject: 'REPORT_AUDIENCE_DEMOGRAPHY',
        },
        {
          action: 'manage',
          subject: 'REPORT_CREATIVES',
        },
        {
          action: 'manage',
          subject: 'REPORT_VTR',
        },
        {
          action: 'manage',
          subject: 'REPORT_AUDIENCE_SITES',
        },
        {
          action: 'manage',
          subject: 'REPORT_AUDIENCE_CATEGORIES',
        },
        {
          action: 'manage',
          subject: 'REPORT_RATE',
        },
        {
          action: 'manage',
          subject: 'REPORT_VTR_MEDIADESK',
        },
        {
          action: 'manage',
          subject: 'REPORT_BRANDS',
        },
        {
          action: 'manage',
          subject: 'ACCESS_PERSONAL_CABINET',
        },
        {
          action: 'manage',
          subject: 'ACCESS_MEDIADESK_MDPROGRAMMATIC_CABINET',
        },
      ],
    },
    {
      id: 235,
      name: 'Lisi',
      password: '666666',
      // eslint-disable-next-line global-require
      avatar: require('@/assets/images/avatars/2.png'),
      email: 'lisi@lisi.ru',
      role: 'PROVIDERS', // group || advertiser = 2
      advertisers: null, // adv_id || empty if user role advertiser
      dashboardAccess: true, // edit_access || true if 6
      custom_properties: { type: 'properties.advertiser', objectId: '33323fb7-735e-43fb-b8cf-515973707eb2', agency: 'Лисиновое' },
      abilities: [
        {
          action: 'manage',
          subject: 'ADVERTISING_CAMPAIGNS',
        },
        {
          action: 'manage',
          subject: 'REPORTS',
        },
        {
          action: 'manage',
          subject: 'REPORT_CITY',
        },
        {
          action: 'manage',
          subject: 'REPORT_CAMPAIGN_STATISTICS',
        },
        {
          action: 'manage',
          subject: 'ACCESS_PERSONAL_CABINET',
        },
        {
          action: 'manage',
          subject: 'ACCESS_MEDIADESK_MDPROGRAMMATIC_CABINET',
        },
      ],
    },
  ],
}

// ! These two secrets shall be in .env file and not in any other file
const jwtConfig = {
  secret: 'dd5f3089-40c3-403d-af14-d0c228b05cb4',
  refreshTokenSecret: '7c4c1c50-3230-45bf-9eae-c9b2e401c767',
}

mock.onPost('/auth/login').reply(request => {
  const { email, password } = JSON.parse(request.data)

  let error = {
    email: ['Что-то пошло не так.'],
  }

  const user = data.users.find(u => u.email === email && u.password === password)

  if (user) {
    if (user.dashboardAccess) {
      try {
        const accessToken = jwt.sign({ id: user.id }, jwtConfig.secret, { expiresIn: '4h' })

        const response = {
          accessToken,
        }

        return [200, response]
      } catch (e) {
        error = e
      }
    } else {
      error = {
        access: ['Отказано в доступе'],
      }

      return [400, { error }]
    }
  } else {
    error = {
      email: ['Неверный логин или пароль'],
    }
  }

  return [400, { error }]
})

mock.onGet('/auth/me').reply(config => {
  const token = config.headers.Authorization.split(' ')[1]

  // get the decoded payload and header
  const decoded = jwt.decode(token, { complete: true })
  const { id: userId } = decoded.payload

  const user = JSON.parse(JSON.stringify(data.users.find(u => u.id === userId)))
  delete user.password

  return [200, { user }]
})
